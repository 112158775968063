import React from 'react'

import PropTypes from 'prop-types'

import './home-features3.css'

const HomeFeatures3 = (props) => {
  return (
    <div className="home-features3-layout349 thq-section-padding">
      <div className="home-features3-max-width thq-section-max-width">
        <div className="home-features3-image-container">
          <img
            alt={props.feature1ImageAlt}
            src={props.feature1ImageSrc}
            className="thq-img-ratio-16-9"
          />
        </div>
        <div className="home-features3-content">
          <div className="home-features3-section-title">
            <span className="thq-body-small">{props.feature1Slogan}</span>
            <div className="home-features3-content1">
              <h2 className="thq-heading-2">{props.feature1Title}</h2>
              <p className="thq-body-large">{props.feature1Description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

HomeFeatures3.defaultProps = {
  feature1Slogan: 'Cutting edge Generative AI technology',
  feature1ImageAlt: 'AI-Powered Writing Assistance',
  feature1Description:
    'Our platform uses Google Cloud platform and Gemini AI to build powerful AI content for your book',
  feature1ImageSrc:
    'https://images.unsplash.com/photo-1625657395275-3fac378fa9a8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxNjc3NTQ0Nnw&ixlib=rb-4.0.3&q=80&w=1080',
  feature1Title: 'Powered by Google Gemini AI',
}

HomeFeatures3.propTypes = {
  feature1Slogan: PropTypes.string,
  feature1ImageAlt: PropTypes.string,
  feature1Description: PropTypes.string,
  feature1ImageSrc: PropTypes.string,
  feature1Title: PropTypes.string,
}

export default HomeFeatures3
