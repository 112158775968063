import React from 'react'

import { Helmet } from 'react-helmet'

import TopNavbar from '../components/top-navbar'
import PaymentComp1 from '../components/payment-comp1'
import PaymentComp2 from '../components/payment-comp2'
import Footer from '../components/footer'
import './payment.css'

const Payment = (props) => {
  return (
    <div className="payment-container">
      <Helmet>
        <title>Payment - Write your own book</title>
        <meta property="og:title" content="Payment - Write your own book" />
      </Helmet>
      <div className="payment-navbar1">
        <TopNavbar></TopNavbar>
      </div>
      <div className="payment-cta2">
        <PaymentComp1></PaymentComp1>
      </div>
      <div className="payment-footer11">
        <Footer></Footer>
      </div>
    </div>
  )
}

export default Payment
