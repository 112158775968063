import React from 'react'
import PropTypes from 'prop-types'
import './home-comp2.css'
import { Link } from 'react-router-dom';

const HomeComp2 = (props) => {
  return (
    <div className="home-comp2-container thq-section-padding">
      <div className="home-comp2-max-width thq-section-max-width">
        <div className="home-comp2-content">
          <h2 className="home-comp2-heading1 thq-heading-2">
            {props.heading1}
          </h2>
          <p className="home-comp2-content1 thq-body-large">{props.content1}</p>
          <div className="home-comp2-actions">
            <button className="thq-button-filled home-comp2-button">
              <span className="home-comp2-action1 thq-body-small">
                <Link to="/book-details" className="home-comp2-action1 thq-body-small">{props.writebook}</Link>
              </span>
            </button>
            <button className="thq-button-outline home-comp2-button1">
              <span className="home-comp2-action2 thq-body-small">
                {props.action2}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

HomeComp2.defaultProps = {
  action2: 'Learn More',
  content1:
    'Sign up today and begin your journey to becoming a published author.',
  writebook: 'Start Writing Now',
  heading1: 'Ready to Write Your Book?',
}

HomeComp2.propTypes = {
  action2: PropTypes.string,
  content1: PropTypes.string,
  action1: PropTypes.string,
  heading1: PropTypes.string,
  writebook: PropTypes.string,
}

export default HomeComp2
