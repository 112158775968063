import React from 'react'

import PropTypes from 'prop-types'

import './download-book-comp1.css'

const DownloadBookComp1 = (props) => {
  return (
    <div className="download-book-comp1-container thq-section-padding">
      <div className="download-book-comp1-max-width thq-section-max-width">
        <div className="download-book-comp1-content">
          <h2 className="download-book-comp1-heading1 thq-heading-2">
            {props.heading1}
          </h2>
          <p className="download-book-comp1-content1 thq-body-large">
            {props.content1}
          </p>
          <div className="download-book-comp1-actions">
            <button className="thq-button-filled download-book-comp1-button">
              <span className="download-book-comp1-action1 thq-body-small">
                {props.action1}
              </span>
            </button>
            <button className="thq-button-outline download-book-comp1-button1">
              <span className="download-book-comp1-action2 thq-body-small">
                {props.action2}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

DownloadBookComp1.defaultProps = {
  action1: 'Get Started Now',
  heading1: 'Start Writing Your Book with AI Assistance',
  content1:
    'Experience the power of AI in book writing and create your masterpiece effortlessly.',
  action2: 'Learn More',
}

DownloadBookComp1.propTypes = {
  action1: PropTypes.string,
  heading1: PropTypes.string,
  content1: PropTypes.string,
  action2: PropTypes.string,
}

export default DownloadBookComp1
