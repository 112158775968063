import React from 'react'
import PropTypes from 'prop-types'
import './login-navbar.css'
import { Link } from 'react-router-dom';

const LoginNavbar = (props) => {

  return (
    <div className={`login-navbar-container ${props.rootClassName} `}>
      <header data-thq="thq-navbar" className="login-navbar-navbar-interactive">
        <img
          alt={props.imageAlt}
          src={props.imageSrc}
          className="login-navbar-image"
        />
        <div data-thq="thq-navbar-nav" className="login-navbar-desktop-menu">
          <nav className="login-navbar-links"></nav>
          <div className="login-navbar-buttons">
            
          </div>
        </div>
        <div data-thq="thq-burger-menu" className="login-navbar-burger-menu">
          <svg viewBox="0 0 1024 1024" className="login-navbar-icon">
            <path
              d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
              className=""
            ></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="login-navbar-mobile-menu">
          <div className="top-navbar-buttons1">
            <button className="thq-button-filled">
              <Link to="/" className="thq-link thq-body-small">Login</Link>
            </button>
            <button className="thq-button-outline">
              <Link to="/signup" className="thq-link thq-body-small">Register</Link>
            </button>
          </div>
        </div>
      </header>
    </div>
  )
}

LoginNavbar.defaultProps = {
  link3: 'Your Books',
  imageSrc: '/logo-transparent-png-200h.png',
  rootClassName: '',
  logoAlt: 'AI Book Writing',
  link2: 'Write a book',
  link5: 'Online Course',
  link1: 'Home',
  imageAlt: 'image',
  logoSrc:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/84ec08e8-34e9-42c7-9445-d2806d156403/fac575ac-7a41-484f-b7ac-875042de11f8?org_if_sml=1&force_format=original',
  link4: 'Pricing Plans',
}

LoginNavbar.propTypes = {
  link3: PropTypes.string,
  imageSrc: PropTypes.string,
  rootClassName: PropTypes.string,
  logoAlt: PropTypes.string,
  link2: PropTypes.string,
  link5: PropTypes.string,
  link1: PropTypes.string,
  imageAlt: PropTypes.string,
  logoSrc: PropTypes.string,
  link4: PropTypes.string,
}

export default LoginNavbar
