import React from 'react'

import { Helmet } from 'react-helmet'

import TopNavbar from '../components/top-navbar'
import CTA15 from '../components/cta15'
import TableOfContentsComp from '../components/table-of-contents-comp'
import Footer from '../components/footer'
import './table-of-contents.css'

const TableOfContents = (props) => {
  return (
    <div className="table-of-contents-container">
      <Helmet>
        <title>TableOfContents - Write your own book</title>
        <meta
          property="og:title"
          content="TableOfContents - Write your own book"
        />
      </Helmet>
      <div className="table-of-contents-navbar1">
        <TopNavbar></TopNavbar>
      </div>
      <div className="table-of-contents-cta2">
        <CTA15></CTA15>
      </div>
      <div className="table-of-contents-contentlist3">
        <TableOfContentsComp></TableOfContentsComp>
      </div>
      <div className="table-of-contents-footer11">
        <Footer></Footer>
      </div>
    </div>
  )
}

export default TableOfContents
