import React from 'react'

import { Helmet } from 'react-helmet'

import TopNavbar from '../components/top-navbar'
import YourAccountComp1 from '../components/your-account-comp1'
import YourAccountComp2 from '../components/your-account-comp2'
import Footer from '../components/footer'
import './your-account.css'

const YourAccount = (props) => {
  return (
    <div className="your-account-container">
      <Helmet>
        <title>YourAccount - Write your own book</title>
        <meta property="og:title" content="YourAccount - Write your own book" />
      </Helmet>
      <div className="your-account-navbar1">
        <TopNavbar></TopNavbar>
      </div>
      <div className="your-account-cta2">
        <YourAccountComp1></YourAccountComp1>
      </div>
      <div className="your-account-contentlist3">
        <YourAccountComp2></YourAccountComp2>
      </div>
      <div className="your-account-footer11">
        <Footer></Footer>
      </div>
    </div>
  )
}

export default YourAccount
