import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.css'
import ReadyToWriteBook from './views/ready-to-write-book'
import Pricing from './views/pricing'
import About from './views/about'
import Home from './views/home'
import OnlineCourses from './views/online-courses'
import ContactUs from './views/contact-us'
import Terms from './views/terms'
import Privacy from './views/privacy'
import Login from './views/login'
import Logout from './views/logout'
import Signup from './views/signup'
import BookDetails from './views/book-details'
import TableOfContents from './views/table-of-contents'
import BookTitles from './views/book-titles'
import SetLengthAndPart from './views/set-length-and-part'
import BookProofReading from './views/book-proof-reading'
import DownloadBook from './views/download-book'
import ProofFinalLayout from './views/proof-final-layout'
import YourBooks from './views/your-books'
import YourAccount from './views/your-account'
import UsageLog from './views/usage-log'
import Profile from './views/profile'
import Cookies from './views/cookies'
import Payment from './views/payment'
import ChapterProofing from './views/chapter-proofing'
import NotFound from './views/not-found'
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import ForgotPassword from './components/forgotpassword'; // Import the new component
import BookPage from './components/book-page'; 


const firebaseConfig = {
  apiKey: process.env.REACT_APP_YOUR_API_KEY,
  authDomain: process.env.REACT_APP_YOUR_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_YOUR_PROJECT_ID,
  storageBucket: process.env.REACT_APP_YOUR_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_YOUR_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_YOUR_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const token = sessionStorage.getItem('token');
  if (token === "Invalid") {
    return <Redirect to="/login" />;
  }
  
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

const App = () => {
  return (
    <Router>
      <Switch>
        <ProtectedRoute component={ReadyToWriteBook} exact path="/ready-to-write-book" />
        <ProtectedRoute component={Pricing} exact path="/pricing" />
        <ProtectedRoute component={About} exact path="/about" />
        <ProtectedRoute component={Home} exact path="/home" />
        <ProtectedRoute component={Login} exact path="/" />
        <ProtectedRoute component={Logout} exact path="/logout" />
        <ProtectedRoute component={ForgotPassword} exact path="/forgot-password" />
        <ProtectedRoute component={Signup} exact path="/signup" />
        <ProtectedRoute component={OnlineCourses} exact path="/online-courses" />
        <ProtectedRoute component={ContactUs} exact path="/contact-us" />
        <ProtectedRoute component={Terms} exact path="/terms" />
        <ProtectedRoute component={Privacy} exact path="/privacy" />
        <ProtectedRoute component={Login} exact path="/login" />
        <ProtectedRoute component={BookDetails} exact path="/book-details" />
        <ProtectedRoute component={BookPage} exact path="/book/:bookId" />
        <ProtectedRoute component={TableOfContents} exact path="/table-of-contents" />
        <ProtectedRoute component={BookTitles} exact path="/book-titles" />
        <ProtectedRoute component={SetLengthAndPart} exact path="/set-length-and-part" />
        <ProtectedRoute component={BookProofReading} exact path="/book-proof-reading" />
        <ProtectedRoute component={DownloadBook} exact path="/download-book" />
        <ProtectedRoute component={ProofFinalLayout} exact path="/proof-final-layout" />
        <ProtectedRoute component={YourBooks} exact path="/your-books" />
        <ProtectedRoute component={YourAccount} exact path="/your-account" />
        <ProtectedRoute component={UsageLog} exact path="/usage-log" />
        <ProtectedRoute component={Profile} exact path="/profile" />
        <ProtectedRoute component={Cookies} exact path="/cookies" />
        <ProtectedRoute component={Payment} exact path="/payment" />
        <ProtectedRoute component={ChapterProofing} exact path="/chapter-proofing" />
        <ProtectedRoute component={NotFound} path="**" />
        <ProtectedRoute 
          path="/help" 
          component={() => ( 
            <iframe src="https://bookbuilder.pro/help.html" title="Help" width="100%" height="600px" />
          )}
        />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
