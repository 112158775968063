import React from 'react'

import { Helmet } from 'react-helmet'

import TopNavbar from '../components/top-navbar'
import BookProofReadingComp1 from '../components/book-proof-reading-comp1'
import BookProofReadingComp2 from '../components/book-proof-reading-comp2'
import Footer from '../components/footer'
import './book-proof-reading.css'

const BookProofReading = (props) => {
  return (
    <div className="book-proof-reading-container">
      <Helmet>
        <title>BookProofReading - Write your own book</title>
        <meta
          property="og:title"
          content="BookProofReading - Write your own book"
        />
      </Helmet>
      <div className="book-proof-reading-navbar1">
        <TopNavbar></TopNavbar>
      </div>
      <div className="book-proof-reading-cta2">
        <BookProofReadingComp1></BookProofReadingComp1>
      </div>
      <div className="book-proof-reading-contentlist3">
        <BookProofReadingComp2></BookProofReadingComp2>
      </div>
      <div className="book-proof-reading-footer11">
        <Footer></Footer>
      </div>
    </div>
  )
}

export default BookProofReading
