import React from 'react'

import PropTypes from 'prop-types'

import './privacy1.css'

const Privacy1 = (props) => {
  return (
    <div className="privacy1-container thq-section-padding">
      <div className="privacy1-max-width thq-section-max-width">
        <div className="privacy1-content thq-flex-column">
          {/* <ul className="thq-flex-column">
            <li className="thq-flex-column list-item">
              <h2 className="privacy1-heading1 thq-heading-2">
                {props.heading1}
              </h2>
              <p className="thq-body-small">{props.content1}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading2}</h3>
              <p className="thq-body-small">{props.content2}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading3}</h3>
              <p className="thq-body-small">{props.content3}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading4}</h3>
              <p className="thq-body-small">{props.content4}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading5}</h3>
              <p className="thq-body-small">{props.content5}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading6}</h3>
              <p className="thq-body-small">{props.content6}</p>
            </li>
          </ul> */}

          <h1>Privacy Policy for A.C.N. 679 972 199 PTY LTD</h1>

          <p>
          A.C.N. 679 972 199 PTY LTD ("we," "us," or "our") is committed to protecting the privacy of our users. This Privacy Policy explains how we collect, use, and safeguard your personal information when you use our AI-assisted book writing website. We are registered in Australia and comply with the Australian Privacy Act 1988, the European General Data Protection Regulation (GDPR), and relevant US privacy laws.
          </p>

          <h2>Information We Collect</h2>

          <p>
          We collect the following information from you:
          </p>

          <ul>
          <li><b>Email address:</b> We collect your email address to create and manage your account, communicate with you about our services, and send you important updates.</li>
          <li><b>Book content:</b> When you use our AI-assisted writing features, we process the content of your book to provide you with suggestions and improvements. We do not share your book content with any third parties without your explicit consent.</li>
          </ul>

          <p>
          We also use third-party services that may collect additional information:
          </p>

          <ul>
          <li><b>Google Firebase:</b> We use Google Firebase to handle user authentication and login. Firebase may collect information about your device and usage patterns. Please refer to <a href="https://policies.google.com/privacy">Google's privacy policy</a> for more information.</li>
          <li><b>Stripe:</b> We use Stripe to process payments. Stripe collects your address for taxation purposes. Please refer to <a href="https://stripe.com/privacy">Stripe's privacy policy</a> for more information.</li>
          </ul>

          <p>
          We minimize data collection and only collect the information necessary to provide and improve our services.
          </p>

          <h2>How We Use Your Information</h2>

          <p>
          We use your information for the following purposes:
          </p>

          <ul>
          <li><b>Account creation and management:</b> To create and manage your account, enable you to log in, and provide you with access to our services.</li>
          <li><b>AI-assisted book writing:</b> To process your book content and provide you with AI-powered suggestions and improvements. We obtain your explicit consent for this processing.</li>
          <li><b>Communication:</b> To send you important updates, service announcements, and respond to your inquiries.</li>
          <li><b>Payment processing:</b> To process payments for our services through Stripe.</li>
          <li><b>Improving our services:</b> To analyze usage patterns, identify areas for improvement, and develop new features.</li>
          </ul>

          <h2>Data Sharing and Disclosure</h2>

          <p>
          We may share your information with the following third parties:
          </p>

          <ul>
          <li><b>Google Firebase:</b> To handle user authentication and login.</li>
          <li><b>Stripe:</b> To process payments.</li>
          <li><b>Service providers:</b> We may engage trusted third-party service providers to perform functions on our behalf, such as hosting, data analysis, and customer support. These providers have access to your information only to perform these tasks and are obligated to protect your information.</li>
          </ul>

          <p>
          We do not sell or rent your personal information to any third parties.
          </p>

          <p>
          We may disclose your information in the following circumstances:
          </p>

          <ul>
          <li><b>Legal compliance:</b> To comply with applicable laws, regulations, or legal processes.</li>
          <li><b>Protection of rights:</b> To protect our rights, property, or safety, or the rights, property, or safety of others.</li>
          <li><b>Business transfers:</b> In connection with a merger, acquisition, or sale of all or a portion of our assets.</li>
          </ul>

          <h2>Data Security</h2>

          <p>
          We take reasonable measures to protect your information from unauthorized access, use, or disclosure. We use encryption, secure servers, access controls, and regular security audits to safeguard your data. However, no method of transmission or storage is completely secure, and we cannot guarantee the absolute security of your information.
          </p>

          <h2>Data Retention</h2>

          <p>
          We retain your information for as long as necessary to provide you with our services and fulfill the purposes outlined in this Privacy Policy. You can request the deletion of your account and data at any time.
          </p>

          <h2>User Rights</h2>

          <p>
          You have the following rights regarding your personal information:
          </p>

          <ul>
          <li><b>Access:</b> You have the right to request access to your personal information.</li>
          <li><b>Rectification:</b> You have the right to request the correction of inaccurate personal information.</li>
          <li><b>Erasure:</b> You have the right to request the deletion of your personal information.</li>
          <li><b>Objection:</b> You have the right to object to the processing of your personal information.</li>
          <li><b>Data portability:</b> You have the right to request a copy of your personal information in a structured, commonly used, and machine-readable format.</li>
          </ul>

          <p>
          To exercise any of these rights, please contact us using the information provided below.
          </p>

          <h2>International Data Transfers</h2>

          <p>
          We are based in Australia, but we may transfer your information to third-party service providers located in other countries. We ensure that any such transfers comply with applicable data protection laws, including the GDPR, by using mechanisms such as Standard Contractual Clauses.
          </p>

          <h2>Children's Privacy</h2>

          <p>
          Our services are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete the information as soon as possible.
          </p>

          <h2>Changes to this Privacy Policy</h2>

          <p>
          We may update this Privacy Policy from time to time. We will notify you of any material changes by posting the updated policy on our website or through other communication channels.
          </p>

          <h2>Contact Information</h2>

          <p>
          If you have any questions or concerns about this Privacy Policy or our data practices, please contact us using contact form
          </p>

        </div>
      </div>
    </div>
  )
}

Privacy1.defaultProps = {
  heading6: 'Table of Contents Generation',
  heading4: 'Generative AI Technology',
  heading2: 'Grammar Checks',
  heading1: 'Personalized Writing Suggestions',
  content6:
    'Effortlessly generate a table of contents for your book to organize your content effectively.',
  content4:
    'Utilize cutting-edge Generative AI technology powered by Google Gemini AI to elevate your writing experience.',
  heading5: 'Security and Confidentiality',
  content2:
    'Ensure your book is error-free with advanced grammar checks and corrections provided by the AI-powered platform.',
  content5:
    'Rest assured that your content is secure and confidential while using the platform to create your book.',
  content3:
    "Get recommendations on how to improve your book's content and structure to engage readers effectively.",
  content1:
    'Receive tailored writing suggestions based on your writing style and content to enhance your book.',
  heading3: 'Content Recommendations',
}

Privacy1.propTypes = {
  heading6: PropTypes.string,
  heading4: PropTypes.string,
  heading2: PropTypes.string,
  heading1: PropTypes.string,
  content6: PropTypes.string,
  content4: PropTypes.string,
  heading5: PropTypes.string,
  content2: PropTypes.string,
  content5: PropTypes.string,
  content3: PropTypes.string,
  content1: PropTypes.string,
  heading3: PropTypes.string,
}

export default Privacy1
