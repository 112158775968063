import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './payment-comp1.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { initializeApp } from 'firebase/app';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main, // Color for header cells
  color: theme.palette.common.white,
  fontWeight: 'bold',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover, // Color for odd rows
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const firebaseConfig = {
  apiKey: process.env.REACT_APP_YOUR_API_KEY,
  authDomain: process.env.REACT_APP_YOUR_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_YOUR_PROJECT_ID,
  storageBucket: process.env.REACT_APP_YOUR_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_YOUR_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_YOUR_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const PaymentComp1 = (props) => {
  const [amountused, setAmountused] = useState('');
  const [amount, setAmount] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const [showErrorMessage, setShowErrorMessage] = useState(true); // Declare showErrorMessage

  const handlePaymentClick = () => {
    let client_reference_id = sessionStorage.getItem('userid');
    //prod URL
    window.location.href = process.env.REACT_APP_PAYMENT_LINK_PROD + client_reference_id;
    
    //test URL
    //window.location.href = 'https://buy.stripe.com/test_6oE9E3e8wc6ueLmaEE?client_reference_id=' + client_reference_id;
    //window.location.href = 'https://buy.stripe.com/test_5kAaI76G4b2qgTu4gh?client_reference_id=' + client_reference_id;
  };

  useEffect(() => {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setShowErrorMessage(false); // Hide error message
      } else {
        const storedUserid = sessionStorage.getItem('userid');
        //console.log("storedUserid from yourbook:"+storedUserid)
        if (storedUserid) {
          setShowErrorMessage(false); // Hide error message
        }
      }
    });

    const fetchAmount = async () => {
      setIsLoading(true);
      setFetchError(null);
      const userid = sessionStorage.getItem('userid');
      try {
        const response = await fetch(process.env.REACT_APP_USAGE, {
          method: 'POST',
          body: JSON.stringify({ userid: userid }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + process.env.API_KEY,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }

        const data = await response.json();
        setAmountused(data.amountused);
        setAmount(data.amount);
      } catch (err) {
        //console.error('Error fetching usage data:', err);
        setFetchError('An error occurred while fetching usage data.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchAmount(); // Call the function to fetch data when the component mounts
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <div className="payment-comp1-container thq-section-padding">
      <div className="payment-comp1-max-width thq-section-max-width">
        {showErrorMessage && (
          <Typography variant="h6" color="error" sx={{ m: 2 }}>
            Please log in before making any payment
          </Typography>
        )}
        <div className="payment-comp1-content">
          <h2 className="payment-comp1-heading1 thq-heading-2">
            {props.heading1}
          </h2>
          <p className="payment-comp1-content1 thq-body-large">
            {props.content1}
          </p>
          <p className="payment-comp1-content1 thq-body-large">
            {props.content2}
          </p>

          {/* Display usage data in a table */}
          {isLoading ? (
            <Typography variant="body1">Loading usage data...</Typography>
          ) : fetchError ? (
            <Typography variant="body1" color="error">{fetchError}</Typography>
          ) : (
            <TableContainer sx={{ width: 'fit-content', margin: '16px auto' }}> {/* Adjust width and margin */}
              <Table size="small" aria-label="usage table"> {/* Set table size to small */}
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Amount Used</StyledTableCell> {/* Use styled header cell */}
                    <StyledTableCell>Total Amount</StyledTableCell> {/* Use styled header cell */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow> {/* Use styled row */}
                    <TableCell>${amountused}</TableCell>
                    <TableCell>${amount}</TableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <div className="payment-comp1-actions">
            <button className="thq-button-filled payment-comp1-button" disabled={showErrorMessage || fetchError} onClick={handlePaymentClick}>
              <span className="payment-comp1-action1 thq-body-small">
                {props.action1}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

PaymentComp1.defaultProps = {
  action1: 'Pay Now',
  heading1: 'Start Writing Your Book with AI Assistance',
  content1:
    'Experience the power of AI in writing your book.',
  content2:
    'Add money to your account',
  action2: 'Learn More',
}

PaymentComp1.propTypes = {
  action1: PropTypes.string,
  heading1: PropTypes.string,
  content1: PropTypes.string,
  action2: PropTypes.string,
}

export default PaymentComp1
