import React from 'react'

import PropTypes from 'prop-types'

import './usage-log-comp1.css'

const UsageLogComp1 = (props) => {
  return (
    <div className="usage-log-comp1-container thq-section-padding">
      <div className="usage-log-comp1-max-width thq-section-max-width">
        <div className="usage-log-comp1-content">
          <h2 className="usage-log-comp1-heading1 thq-heading-2">
            {props.heading1}
          </h2>
          <p className="usage-log-comp1-content1 thq-body-large">
            {props.content1}
          </p>
          <div className="usage-log-comp1-actions">
            <button className="thq-button-filled usage-log-comp1-button">
              <span className="usage-log-comp1-action1 thq-body-small">
                {props.action1}
              </span>
            </button>
            <button className="thq-button-outline usage-log-comp1-button1">
              <span className="usage-log-comp1-action2 thq-body-small">
                {props.action2}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

UsageLogComp1.defaultProps = {
  action1: 'Sign Up Now',
  heading1: 'Ready to Write Your Book?',
  content1:
    'Join our AI-powered book writing platform today and start your journey to becoming a published author.',
  action2: 'Learn More',
}

UsageLogComp1.propTypes = {
  action1: PropTypes.string,
  heading1: PropTypes.string,
  content1: PropTypes.string,
  action2: PropTypes.string,
}

export default UsageLogComp1
