import React from 'react'

import { Helmet } from 'react-helmet'

import TopNavbar from '../components/top-navbar'
import AboutComp1 from '../components/about-comp1'
import AboutComp2 from '../components/about-comp2'
import AboutComp3 from '../components/about-comp3'
import AboutTeamComp from '../components/about-team-comp'
import Footer from '../components/footer'
import './about.css'

const About = (props) => {
  return (
    <div className="about-container">
      <Helmet>
        <title>About - Write your own book</title>
        <meta property="og:title" content="About - Write your own book" />
      </Helmet>
      <div className="about-navbar1">
        <TopNavbar></TopNavbar>
      </div>
      <div className="about-hero2">
        <AboutComp1></AboutComp1>
      </div>
      <div className="about-stats3">
        <AboutComp2></AboutComp2>
      </div>
      <div className="about-logos4"></div>
      <div className="about-features5">
        <AboutComp3></AboutComp3>
      </div>
      <div className="about-team6">
        <AboutTeamComp></AboutTeamComp>
      </div>
      <div className="about-footer11">
        <Footer></Footer>
      </div>
    </div>
  )
}

export default About
