import React from 'react'

import { Helmet } from 'react-helmet'

import TopNavbar from '../components/top-navbar'
import Logout from '../components/Logout'
import Footer from '../components/footer'
import './login.css'

const LogoutPage = (props) => {
  return (
    <div className="login-container">
      <Helmet>
        <title>Login - Write your own book</title>
        <meta property="og:title" content="Login - Write your own book" />
      </Helmet>
      <TopNavbar rootClassName="login-navbar-root-class-name"></TopNavbar>
      <Logout></Logout>
      <Footer rootClassName="footer-root-class-name"></Footer>
    </div>
  )
}

export default LogoutPage
