import React from 'react'

import { Helmet } from 'react-helmet'

import TopNavbar from '../components/top-navbar'
import DownloadBookComp1 from '../components/download-book-comp1'
import DownloadBookComp2 from '../components/download-book-comp2'
import Footer from '../components/footer'
import './download-book.css'

const DownloadBook = (props) => {
  return (
    <div className="download-book-container">
      <Helmet>
        <title>DownloadBook - Write your own book</title>
        <meta
          property="og:title"
          content="DownloadBook - Write your own book"
        />
      </Helmet>
      <div className="download-book-navbar1">
        <TopNavbar></TopNavbar>
      </div>
      <div className="download-book-cta2">
        <DownloadBookComp1></DownloadBookComp1>
      </div>
      <div className="download-book-contentlist3">
        <DownloadBookComp2></DownloadBookComp2>
      </div>
      <div className="download-book-footer11">
        <Footer></Footer>
      </div>
    </div>
  )
}

export default DownloadBook
