import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './contact-form.css';
import emailjs from '@emailjs/browser'; // Import emailjs library

const ContactForm = (props) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Prepare email data
    // const templateParams = {
    //   from_name: name,
    //   to_name: "Admin",
    //   from_email: email,
    //   message: message,
    // };

    try {
      // Send email using emailjs
      // await emailjs.send(
      //   process.env.REACT_APP_YOUR_SERVICE_ID, // Replace with your EmailJS service ID
      //   process.env.REACT_APP_YOUR_TEMPLATE_ID, // Replace with your EmailJS template ID
      //   templateParams
      // );

      await emailjs.send(
        "service_gyrek93",
        "template_0alb6ps",
        {
          from_name: name,
          to_name: "Admin",
          from_email: email,
          message: message,
        },
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY // Add your public key here
      );

      // Reset form fields and show success message
      setName('');
      setEmail('');
      setMessage('');
      setShowSuccessMessage(true);
      setShowErrorMessage(false);
    } catch (error) {
      // Show error message
      console.error('Error sending email:', error);
      setShowErrorMessage(true);
      setShowSuccessMessage(false);
    }
  };

  return (
    <div className="contact-form-contact9 thq-section-padding">
      <div className="thq-section-max-width thq-flex-row contact-form-max-width">
        <img
          alt={props.imageAlt}
          src={props.imageSrc}
          className="contact-form-image1 thq-img-ratio-4-3"
        />
        <div className="contact-form-content thq-flex-column">
          <div className="contact-form-section-title thq-card">
            <div className="contact-form-content1">
              <h2 className="thq-heading-2">{props.heading1}</h2>
            </div>
          </div>
          <form className="thq-card" onSubmit={handleSubmit}>
            <div className="contact-form-input">
              <label htmlFor="contact-form-3-name" className="thq-body-small">
                Name
              </label>
              <input
                type="text"
                id="contact-form-3-name"
                placeholder="Name"
                className="thq-input"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="contact-form-input1">
              <label htmlFor="contact-form-3-email" className="thq-body-small">
                Email
              </label>
              <input
                type="email"
                id="contact-form-3-email"
                required="true"
                placeholder="Email"
                className="thq-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="contact-form-container">
              <label htmlFor="contact-form-3-message" className="thq-body-small">
                Message
              </label>
              <textarea
                id="contact-form-3-message"
                rows="3"
                placeholder="Enter your message"
                className="thq-input"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              ></textarea>
            </div>

            {/* Success/Error Messages */}
            {showSuccessMessage && (
              <p className="success-message">Message sent successfully!</p>
            )}
            {showErrorMessage && (
              <p className="error-message">
                Error sending message. Please try again later.
              </p>
            )}

            <button type="submit" className="contact-form-button thq-button-filled">
              <span className="thq-body-small">{props.action}</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

ContactForm.defaultProps = {
  action: 'Submit',
  content1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
  content2: 'Get in touch with us',
  imageAlt: 'Image1',
  imageSrc:
    'https://images.unsplash.com/photo-1514894780887-121968d00567?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxNjc5NDM0Mnw&ixlib=rb-4.0.3&q=80&w=1080',
  heading1: 'Contact us',
}

ContactForm.propTypes = {
  action: PropTypes.string,
  content1: PropTypes.string,
  content2: PropTypes.string,
  imageAlt: PropTypes.string,
  imageSrc: PropTypes.string,
  heading1: PropTypes.string,
}

export default ContactForm
