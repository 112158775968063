import React from 'react'

import { Helmet } from 'react-helmet'

import TopNavbar from '../components/top-navbar'
import ProofFinalLayoutComp1 from '../components/proof-final-layout-comp1'
import ProofFinalLayoutComp2 from '../components/proof-final-layout-comp2'
import Footer from '../components/footer'
import './proof-final-layout.css'

const ProofFinalLayout = (props) => {
  return (
    <div className="proof-final-layout-container">
      <Helmet>
        <title>ProofFinalLayout - Write your own book</title>
        <meta
          property="og:title"
          content="ProofFinalLayout - Write your own book"
        />
      </Helmet>
      <div className="proof-final-layout-navbar1">
        <TopNavbar></TopNavbar>
      </div>
      <div className="proof-final-layout-cta2">
        <ProofFinalLayoutComp1></ProofFinalLayoutComp1>
      </div>
      <div className="proof-final-layout-contentlist3">
        <ProofFinalLayoutComp2></ProofFinalLayoutComp2>
      </div>
      <div className="proof-final-layout-footer11">
        <Footer></Footer>
      </div>
    </div>
  )
}

export default ProofFinalLayout
