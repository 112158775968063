import React from 'react'

import PropTypes from 'prop-types'

import './about-comp2.css'

const AboutComp2 = (props) => {
  return (
    <div className="about-comp2-container thq-section-padding">
      <div className="about-comp2-max-width thq-section-max-width">
        <div className="thq-flex-column">
          <img
            alt={props.image1Alt}
            src={props.image1Src}
            className="thq-img-ratio-1-1 about-comp2-image"
          />
        </div>
        <div className="about-comp2-container2 thq-flex-column">
          <span className="thq-body-small">{props.content1}</span>
          <h2 className="thq-heading-2">{props.heading1}</h2>
          <p className="thq-body-large">{props.content2}</p>
          <div className="about-comp2-container3 thq-grid-2">
            <div className="about-comp2-container4">
              <h2 className="thq-heading-2">{props.stat1}</h2>
              <span className="thq-body-small">{props.stat1Description}</span>
            </div>
            <div className="about-comp2-container5">
              <h2 className="thq-heading-2">{props.stat2}</h2>
              <span className="thq-body-small">{props.stat2Description}</span>
            </div>
          </div>
          <div className="about-comp2-container6 thq-grid-2">
            <div className="about-comp2-container7">
              <h2 className="thq-heading-2">{props.stat3}</h2>
              <span className="thq-body-small">{props.stat3Description}</span>
            </div>
            <div className="about-comp2-container8">
              <h2 className="thq-heading-2">{props.stat4}</h2>
              <span className="thq-body-small">{props.stat4Description}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

AboutComp2.defaultProps = {
  image1Src:
    'https://images.unsplash.com/photo-1465929639680-64ee080eb3ed?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxNjk4MzQwNHw&ixlib=rb-4.0.3&q=80&w=1080',
  heading1: 'Key Features',
  stat4: 'Multiple Download Formats',
  stat2Description:
    'Advanced AI algorithms provide writing assistance and suggestions.',
  stat2: 'Personalized Suggestions',
  stat3: 'Secure Platform',
  image1Alt: 'AI Writing Assistance',
  content2:
    'Receive content recommendations tailored to your writing style and genre.',
  stat1: 'AI Writing Assistance',
  stat4Description:
    'Download your completed books in various formats such as PDF, Word, etc.',
  stat3Description:
    'Ensure the security and confidentiality of your content with our platform.',
  stat1Description:
    'Utilize AI-powered algorithms to assist you in writing your book effectively.',
  content1:
    'Get personalized writing suggestions and grammar checks to enhance your book writing process.',
}

AboutComp2.propTypes = {
  image1Src: PropTypes.string,
  heading1: PropTypes.string,
  stat4: PropTypes.string,
  stat2Description: PropTypes.string,
  stat2: PropTypes.string,
  stat3: PropTypes.string,
  image1Alt: PropTypes.string,
  content2: PropTypes.string,
  stat1: PropTypes.string,
  stat4Description: PropTypes.string,
  stat3Description: PropTypes.string,
  stat1Description: PropTypes.string,
  content1: PropTypes.string,
}

export default AboutComp2
