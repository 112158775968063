import React from 'react'
import { Helmet } from 'react-helmet'
import TopNavbar from '../components/top-navbar'
import YourBooksComp1 from '../components/your-books-comp1'
import Footer from '../components/footer'
import './your-books.css'

const YourBooks = (props) => {
  return (
    <div className="your-books-container">
      <Helmet>
        <title>YourBooks - Write your own book</title>
        <meta property="og:title" content="YourBooks - Write your own book" />
      </Helmet>
      <div className="your-books-navbar1">
        <TopNavbar></TopNavbar>
      </div>
      <div className="your-books-cta2">
        <YourBooksComp1></YourBooksComp1>
      </div>
      <div className="your-books-footer11">
        <Footer></Footer>
      </div>
    </div>
  )
}

export default YourBooks
