import React from 'react'
import PropTypes from 'prop-types'
import './home-comp1.css'
import { Link } from 'react-router-dom';

const HomeComp1 = (props) => {
  return (
    <div className="home-comp1-header9">
      <img
        alt={props.image1Alt}
        src={props.image1Src}
        className="thq-img-ratio-16-9"
      />
      <div className="home-comp1-content thq-section-padding">
        <div className="home-comp1-max-width thq-section-max-width thq-flex-row">
          <div className="home-comp1-column">
            <h1 className="thq-heading-1 home-comp1-text">{props.heading1}</h1>
          </div>
          <div className="home-comp1-column1">
            <p className="thq-body-large home-comp1-text1">{props.content1}</p>
            <div className="home-comp1-actions">
              <button className="thq-button-filled home-comp1-button">
                <span className="thq-body-small">{props.action1}</span>
              </button>
              <button className="thq-button-outline home-comp1-button1">
                <span className="thq-body-small">
                  <Link to="/book-details" className="thq-link thq-body-small">{props.writebook}</Link>
                </span>                
              </button>
              <button className="thq-button-outline home-comp1-button1">
                <span className="thq-body-small">
                  <Link to="/login" className="thq-link thq-body-small">{props.signup}</Link>
                </span>                
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

HomeComp1.defaultProps = {
  content1:
  'Sign up today and begin your journey to becoming a published author with our AI-powered book writing platform.',  
  writebook: 'Start Writing Now',
  image1Alt: 'Image of a person writing on a laptop',
  action1: 'Watch Video',
  signup: 'Sign Up',
  heading1: 'Write Your Book with AI',
  image1Src:
    'https://images.unsplash.com/photo-1429216967620-ece20ff3a5f9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxNjc3NTQ0Nnw&ixlib=rb-4.0.3&q=80&w=1080',
}

HomeComp1.propTypes = {
  content1: PropTypes.string,
  signup: PropTypes.string,
  image1Alt: PropTypes.string,
  action1: PropTypes.string,
  heading1: PropTypes.string,
  image1Src: PropTypes.string,
  writebook: PropTypes.string,
}

export default HomeComp1
