import React from 'react'

import PropTypes from 'prop-types'

import './table-of-contents-comp.css'

const TableOfContentsComp = (props) => {
  return (
    <div className="table-of-contents-comp-container thq-section-padding">
      <div className="table-of-contents-comp-max-width thq-section-max-width">
        <div className="table-of-contents-comp-content thq-flex-column">
          <ul className="thq-flex-column">
            <li className="list-item thq-flex-column">
              <h2 className="table-of-contents-comp-heading1 thq-heading-2">
                {props.heading1}
              </h2>
              <p className="thq-body-small">{props.content1}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading2}</h3>
              <p className="thq-body-small">{props.content2}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading3}</h3>
              <p className="thq-body-small">{props.content3}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading4}</h3>
              <p className="thq-body-small">{props.content4}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading5}</h3>
              <p className="thq-body-small">{props.content5}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading6}</h3>
              <p className="thq-body-small">{props.content6}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

TableOfContentsComp.defaultProps = {
  content4:
    'Get suggestions on additional content ideas to enrich your book further and make it more engaging for your readers.',
  content3:
    'Ensure your book is error-free with our grammar checks that help in identifying and correcting grammatical mistakes for a polished final product.',
  content6:
    'Rest assured that your content is safe and secure on our platform, with measures in place to protect your work throughout the writing process.',
  heading5: 'Google Gemini AI Integration',
  content1:
    'Our AI-powered platform assists authors in generating content for their books through automated writing, saving time and effort in the writing process.',
  content2:
    'Receive tailored suggestions and recommendations based on your writing style and content to enhance the quality and creativity of your book.',
  heading4: 'Content Recommendations',
  content5:
    'Leverage the power of Google Gemini AI for advanced content creation capabilities that elevate the quality and depth of your book.',
  heading1: 'Automated Writing',
  heading3: 'Grammar Checks',
  heading6: 'Secure Content Storage',
  heading2: 'Personalized Suggestions',
}

TableOfContentsComp.propTypes = {
  content4: PropTypes.string,
  content3: PropTypes.string,
  content6: PropTypes.string,
  heading5: PropTypes.string,
  content1: PropTypes.string,
  content2: PropTypes.string,
  heading4: PropTypes.string,
  content5: PropTypes.string,
  heading1: PropTypes.string,
  heading3: PropTypes.string,
  heading6: PropTypes.string,
  heading2: PropTypes.string,
}

export default TableOfContentsComp
