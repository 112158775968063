import React from 'react'
import PropTypes from 'prop-types'
import './home-faq.css'
import { Link } from 'react-router-dom';

const HomeFAQ = (props) => {
  return (
    <div className="home-faq-faq7 thq-section-padding">
      <div className="home-faq-max-width thq-section-max-width">
        <div className="thq-flex-column">
          <h2 className="thq-heading-2">{props.heading1}</h2>
          <p className="home-faq-text1 thq-body-large">{props.content1}</p>
        </div>
        <div className="thq-flex-column home-faq-list">
          <div className="home-faq-list-item1">
            <p className="home-faq-faq1-question thq-body-large">
              {props.faq1Question}
            </p>
            <span className="thq-body-small">{props.faq1Answer}</span>
          </div>
          <div className="home-faq-list-item2">
            <p className="home-faq-faq2-question thq-body-large">
              {props.faq2Question}
            </p>
            <span className="thq-body-small">{props.faq2Answer}</span>
          </div>
          <div className="home-faq-list-item3">
            <p className="home-faq-faq3-question thq-body-large">
              {props.faq3Question}
            </p>
            <span className="thq-body-small">{props.faq3Answer}</span>
          </div>
          <div className="home-faq-list-item4">
            <p className="home-faq-faq4-question thq-body-large">
              {props.faq4Question}
            </p>
            <span className="thq-body-small">{props.faq4Answer}</span>
          </div>
          <div className="home-faq-list-item5">
            <p className="home-faq-faq5-question thq-body-large">
              {props.faq5Question}
            </p>
            <span className="thq-body-small">{props.faq5Answer}</span>
          </div>
        </div>
        <div className="thq-flex-column">
          <div className="home-faq-content1">
            <h2 className="thq-heading-2">{props.heading2}</h2>
          </div>
          <div className="home-faq-container">
            <button className="thq-button-outline home-faq-button">
              <span className="thq-body-small">
                <Link to="/contact-us" className="thq-link thq-body-small">{props.action1}</Link>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

HomeFAQ.defaultProps = {
  faq1Question: 'How does the AI help in writing a book?',
  content2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  action1: 'Contact',
  faq5Question: 'Can I download my completed book from this website?',
  faq2Question: 'Is my content secure on this website?',
  faq1Answer:
    'Our AI technology assists authors by providing suggestions for plot development, character creation, and even grammar checks to enhance the writing process.',
  faq3Question: 'Can I collaborate with others on my book using this platform?',
  faq3Answer:
    'Currently, our platform is designed for individual use. However, you can share your work with others outside the platform for collaboration.',
  heading1: 'Frequently Asked Questions',
  faq2Answer:
    'Yes, we prioritize the security and confidentiality of your content. Your work is only accessible to you unless you choose to share it.',
  heading2: 'Still have a question?',
  faq4Question: 'How can I access the table of contents for my book?',
  content1: '',
  faq5Answer:
    'Yes, after selecting the titles and finalizing your book, you will have the option to download it in various formats for publishing or personal use.',
  faq4Answer:
    'Once you have input all the necessary details about your book, you will be able to generate and view the table of contents before finalizing it.',
}

HomeFAQ.propTypes = {
  faq1Question: PropTypes.string,
  content2: PropTypes.string,
  action1: PropTypes.string,
  faq5Question: PropTypes.string,
  faq2Question: PropTypes.string,
  faq1Answer: PropTypes.string,
  faq3Question: PropTypes.string,
  faq3Answer: PropTypes.string,
  heading1: PropTypes.string,
  faq2Answer: PropTypes.string,
  heading2: PropTypes.string,
  faq4Question: PropTypes.string,
  content1: PropTypes.string,
  faq5Answer: PropTypes.string,
  faq4Answer: PropTypes.string,
}

export default HomeFAQ
