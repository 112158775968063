import React from 'react'

import { Helmet } from 'react-helmet'

import TopNavbar from '../components/top-navbar'
import HomeComp1 from '../components/home-comp1'
import HomeGeminiComp from '../components/home-gemini-comp'
import HomeFeatures1 from '../components/home-features1'
import HomeFeatures2 from '../components/home-features2'
import HomeFeatures3 from '../components/home-features3'
import HomeComp2 from '../components/home-comp2'
import HomeFAQ from '../components/home-faq'
import ContactUs from '../components/contact-us'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Write your own book</title>
        <meta property="og:title" content="Write your own book" />
      </Helmet>
      <div className="home-navbar1">
        <TopNavbar></TopNavbar>
      </div>
      <div className="home-hero2">
        <HomeComp1 action2="Write a book"></HomeComp1>
      </div>
      <div className="home-logos3"></div>
      <div className="home-gallery4">
        <HomeGeminiComp></HomeGeminiComp>
      </div>
      <div className="home-features5">
        <HomeFeatures1></HomeFeatures1>
      </div>
      <div className="home-features6">
        <HomeFeatures2></HomeFeatures2>
      </div>
      <div className="home-features7">
        <HomeFeatures3></HomeFeatures3>
      </div>
      <div className="home-faq9">
        <HomeFAQ></HomeFAQ>
      </div>
      <div className="home-contact10">
        <ContactUs></ContactUs>
      </div>
      <div className="home-footer11">
        <Footer></Footer>
      </div>
    </div>
  )
}

export default Home
