import React from 'react'

import { Helmet } from 'react-helmet'

import TopNavbar from '../components/top-navbar'
import SetPartAndLengthComp1 from '../components/set-part-and-length-comp1'
import SetLengthAndPartComp from '../components/set-length-and-part-comp'
import Footer from '../components/footer'
import './set-length-and-part.css'

const SetLengthAndPart = (props) => {
  return (
    <div className="set-length-and-part-container">
      <Helmet>
        <title>SetLengthAndPart - Write your own book</title>
        <meta
          property="og:title"
          content="SetLengthAndPart - Write your own book"
        />
      </Helmet>
      <div className="set-length-and-part-navbar1">
        <TopNavbar></TopNavbar>
      </div>
      <div className="set-length-and-part-cta2">
        <SetPartAndLengthComp1></SetPartAndLengthComp1>
      </div>
      <div className="set-length-and-part-contentlist3">
        <SetLengthAndPartComp></SetLengthAndPartComp>
      </div>
      <div className="set-length-and-part-footer11">
        <Footer></Footer>
      </div>
    </div>
  )
}

export default SetLengthAndPart
