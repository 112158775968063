import React from 'react'

import { Helmet } from 'react-helmet'

import TopNavbar from '../components/top-navbar'
import ContactForm from '../components/contact-form'
import Footer from '../components/footer'
import './contact-us.css'

const ContactUs = (props) => {
  return (
    <div className="contact-us-container">
      <Helmet>
        <title>Contact-Us - Write your own book</title>
        <meta property="og:title" content="Contact-Us - Write your own book" />
      </Helmet>
      <div className="contact-us-navbar1">
        <TopNavbar></TopNavbar>
      </div>
      <div className="contact-us-contactform2">
        <ContactForm></ContactForm>
      </div>
      <div className="contact-us-contact3"></div>
      <div className="contact-us-contact4"></div>
      <div className="contact-us-footer11">
        <Footer></Footer>
      </div>
    </div>
  )
}

export default ContactUs
