import React from 'react'

import PropTypes from 'prop-types'

import './your-account-comp2.css'

const YourAccountComp2 = (props) => {
  return (
    <div className="your-account-comp2-container thq-section-padding">
      <div className="your-account-comp2-max-width thq-section-max-width">
        <div className="your-account-comp2-content thq-flex-column">
          <ul className="thq-flex-column">
            <li className="list-item thq-flex-column">
              <h2 className="your-account-comp2-heading1 thq-heading-2">
                {props.heading1}
              </h2>
              <p className="thq-body-small">{props.content1}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading2}</h3>
              <p className="thq-body-small">{props.content2}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading3}</h3>
              <p className="thq-body-small">{props.content3}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading4}</h3>
              <p className="thq-body-small">{props.content4}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading5}</h3>
              <p className="thq-body-small">{props.content5}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading6}</h3>
              <p className="thq-body-small">{props.content6}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

YourAccountComp2.defaultProps = {
  content4:
    'Our platform is powered by Google Gemini AI, ensuring top-notch AI capabilities for an efficient and effective writing experience.',
  content3:
    'Authors have the flexibility to decide whether they want partial AI assistance or full automation in the book writing process.',
  content6:
    'Explore our FAQs section for more information about our AI-powered book writing platform and how it can benefit authors.',
  heading5: 'Sign Up Today',
  content1:
    'Our AI-powered book writing platform uses cutting-edge Generative AI technology to assist authors in writing their books. Authors can choose to write with AI assistance or fully automate the writing process.',
  content2:
    'Our platform provides personalized suggestions, grammar checks, and content recommendations to enhance the writing experience for authors.',
  heading4: 'Google Gemini AI',
  content5:
    'Join our platform today to kickstart your book writing journey with AI assistance and innovative features.',
  heading1: 'How it Works',
  heading3: 'Automation Options',
  heading6: 'FAQs',
  heading2: 'Features',
}

YourAccountComp2.propTypes = {
  content4: PropTypes.string,
  content3: PropTypes.string,
  content6: PropTypes.string,
  heading5: PropTypes.string,
  content1: PropTypes.string,
  content2: PropTypes.string,
  heading4: PropTypes.string,
  content5: PropTypes.string,
  heading1: PropTypes.string,
  heading3: PropTypes.string,
  heading6: PropTypes.string,
  heading2: PropTypes.string,
}

export default YourAccountComp2
