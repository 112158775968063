import React from 'react'

import { Helmet } from 'react-helmet'

import TopNavbar from '../components/top-navbar'
import ProfileComp1 from '../components/profile-comp1'
import ProfileComp2 from '../components/profile-comp2'
import Footer from '../components/footer'
import './profile.css'

const Profile = (props) => {
  return (
    <div className="profile-container">
      <Helmet>
        <title>Profile - Write your own book</title>
        <meta property="og:title" content="Profile - Write your own book" />
      </Helmet>
      <div className="profile-navbar1">
        <TopNavbar></TopNavbar>
      </div>
      <div className="profile-cta2">
        <ProfileComp1></ProfileComp1>
      </div>
      <div className="profile-contentlist3">
        <ProfileComp2></ProfileComp2>
      </div>
      <div className="profile-footer11">
        <Footer></Footer>
      </div>
    </div>
  )
}

export default Profile
