import React from 'react'

import { Helmet } from 'react-helmet'

import TopNavbar from '../components/top-navbar'
import Terms1 from '../components/terms1'
import Footer from '../components/footer'
import './terms.css'

const Terms = (props) => {
  return (
    <div className="terms-container">
      <Helmet>
        <title>Terms - Write your own book</title>
        <meta property="og:title" content="Terms - Write your own book" />
      </Helmet>
      <div className="terms-navbar1">
        <TopNavbar></TopNavbar>
      </div>
      <div className="terms-cta2"></div>
      <div className="terms-contentlist3"></div>
      <div className="terms-footer11"></div>
      <Terms1 rootClassName="terms1-root-class-name"></Terms1>
      <Footer></Footer>
    </div>
  )
}

export default Terms
