import React, { useState } from 'react';
import './sign-in.css'; // Use the same CSS as sign-in.js
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_YOUR_API_KEY,
    authDomain: process.env.REACT_APP_YOUR_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_YOUR_PROJECT_ID,
    storageBucket: process.env.REACT_APP_YOUR_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_YOUR_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_YOUR_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleResetPassword = async (event) => {
    event.preventDefault();
    setMessage(null);
    setErrorMessage(null);

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset email sent. Please check your inbox.');
    } catch (error) {
      if (error.code === 'auth/user-not-found') {
        setErrorMessage('No user found with that email address.');
      } else {
        setErrorMessage('An error occurred. Please try again later.');
      }
      console.error(error);
    }
  };

  return (
    <div className="sign-in-container thq-section-padding">
      <div className="sign-in-max-width thq-section-max-width thq-section-padding">
        <div className="sign-in-form-root">
          <div className="sign-in-form">
            <div className="sign-in-container1">
              <h2 className="sign-in-text thq-heading-2">Reset Your Password</h2>
              <form className="sign-in-form1" onSubmit={handleResetPassword}>
                <div className="sign-in-email">
                  <label htmlFor="email" className="thq-body-large">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    required
                    placeholder="Email address"
                    className="sign-in-textinput thq-body-large thq-input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                {errorMessage && (
                  <p className="error-message" style={{ color: 'red' }}>{errorMessage}</p>
                )}
                {message && (
                  <p className="success-message" style={{ color: 'green' }}>{message}</p>
                )}
                <div className="sign-in-container5">
                  <button
                    type="submit"
                    className="sign-in-button2 thq-button-filled"
                  >
                    <span className="sign-in-text09 thq-body-small">
                      Reset Password
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
