import React from 'react'

import PropTypes from 'prop-types'

import './cookies-comp2.css'

const CookiesComp2 = (props) => {
  return (
    <div className="cookies-comp2-container thq-section-padding">
      <div className="cookies-comp2-max-width thq-section-max-width">
        <div className="cookies-comp2-content thq-flex-column">
          <ul className="thq-flex-column">
            <li className="list-item thq-flex-column">
              <h2 className="cookies-comp2-heading1 thq-heading-2">
                {props.heading1}
              </h2>
              <p className="thq-body-small">{props.content1}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading2}</h3>
              <p className="thq-body-small">{props.content2}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading3}</h3>
              <p className="thq-body-small">{props.content3}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading4}</h3>
              <p className="thq-body-small">{props.content4}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading5}</h3>
              <p className="thq-body-small">{props.content5}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading6}</h3>
              <p className="thq-body-small">{props.content6}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

CookiesComp2.defaultProps = {
  content4:
    'Your content is securely stored on our platform, ensuring that your work is safe and accessible whenever you need it.',
  content3:
    'Get plot development suggestions and recommendations to enhance your storytelling and keep your readers engaged throughout the book.',
  content6:
    'Join our platform now to experience the power of AI in writing and take your book creation process to the next level.',
  heading5: '24/7 Support',
  content1:
    'Our platform offers advanced AI algorithms to assist authors in writing their books, providing writing suggestions, grammar checks, and content recommendations.',
  content2:
    'Authors can choose to write their book with AI assistance or fully automate the writing process using our Google Gemini AI technology.',
  heading4: 'Secure Content Storage',
  content5:
    'Access our FAQs section, contact our support team, and get assistance at any time to start your book writing journey with confidence.',
  heading1: 'AI-Powered Book Writing Platform',
  heading3: 'Plot Development Suggestions',
  heading6: 'Get Started Today',
  heading2: 'Automate Your Writing Process',
}

CookiesComp2.propTypes = {
  content4: PropTypes.string,
  content3: PropTypes.string,
  content6: PropTypes.string,
  heading5: PropTypes.string,
  content1: PropTypes.string,
  content2: PropTypes.string,
  heading4: PropTypes.string,
  content5: PropTypes.string,
  heading1: PropTypes.string,
  heading3: PropTypes.string,
  heading6: PropTypes.string,
  heading2: PropTypes.string,
}

export default CookiesComp2
