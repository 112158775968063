import React from 'react'

import { Helmet } from 'react-helmet'

import TopNavbar from '../components/top-navbar'
import ChapterProofingComp1 from '../components/chapter-proofing-comp1'
import ChapterProofingComp2 from '../components/chapter-proofing-comp2'
import Footer from '../components/footer'
import './chapter-proofing.css'

const ChapterProofing = (props) => {
  return (
    <div className="chapter-proofing-container">
      <Helmet>
        <title>ChapterProofing - Write your own book</title>
        <meta
          property="og:title"
          content="ChapterProofing - Write your own book"
        />
      </Helmet>
      <div className="chapter-proofing-navbar1">
        <TopNavbar></TopNavbar>
      </div>
      <div className="chapter-proofing-cta2">
        <ChapterProofingComp1></ChapterProofingComp1>
      </div>
      <div className="chapter-proofing-contentlist3">
        <ChapterProofingComp2></ChapterProofingComp2>
      </div>
      <div className="chapter-proofing-footer11">
        <Footer></Footer>
      </div>
    </div>
  )
}

export default ChapterProofing
