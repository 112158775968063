import React from 'react'
import PropTypes from 'prop-types'
import './top-navbar.css'
import { Link } from 'react-router-dom';
import { logout } from '../firebase';

const TopNavbar = (props) => {
  const handleLogout = async () => {
    try {
      await logout();
      // Redirect to login page or do something else on logout
      //setUserid(None);
      sessionStorage.removeItem('userid'); // Add userid to sessionStorage
      sessionStorage.removeItem('token'); 
      window.location.href = '/';
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div className={`top-navbar-container ${props.rootClassName} `}>
      <header data-thq="thq-navbar" className="top-navbar-navbar-interactive">
        <img
          alt={props.imageAlt}
          src={props.imageSrc}
          className="top-navbar-image"
        />
        <div data-thq="thq-navbar-nav" className="top-navbar-desktop-menu">
          <nav className="top-navbar-links">
            <Link to="/home" className="thq-link thq-body-small">{props.home}</Link>
            <Link to="/your-books" className="thq-link thq-body-small">{props.books}</Link>
            <Link to="/book-details" className="thq-link thq-body-small">{props.writebook}</Link>
            <Link to="/payment" className="thq-link thq-body-small">{props.payment}</Link>
            <Link to="/online-courses" className="thq-link thq-body-small">{props.online}</Link>
            {/* <Link to="/profile" className="thq-link thq-body-small">{props.profile}</Link> */}
          </nav>
          <div className="top-navbar-buttons">
            <button className="thq-button-outline" onClick={handleLogout}>Logout</button>
          </div>
        </div>
        <div data-thq="thq-burger-menu" className="top-navbar-burger-menu">
          <svg viewBox="0 0 1024 1024" className="top-navbar-icon">
            <path
              d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
              className=""
            ></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="top-navbar-mobile-menu">
          <div className="top-navbar-nav">
            <div className="top-navbar-top">
              <img
                alt={props.logoAlt}
                src={props.logoSrc}
                className="top-navbar-logo"
              />
              <div data-thq="thq-close-menu" className="top-navbar-close-menu">
                <svg viewBox="0 0 1024 1024" className="top-navbar-icon2">
                  <path
                    d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                    className=""
                  ></path>
                </svg>
              </div>
            </div>
            <nav className="top-navbar-links1">
              <span className="thq-link thq-body-small"><Link to="/home" className="thq-link thq-body-small">{props.home}</Link></span>
              <span className="thq-link thq-body-small"><Link to="/your-books" className="thq-link thq-body-small">{props.books}</Link></span>
              <span className="thq-link thq-body-small"><Link to="/book-details" className="thq-link thq-body-small">{props.writebook}</Link></span>
              <span className="thq-link thq-body-small"><Link to="/payment" className="thq-link thq-body-small">{props.payment}</Link></span>
              <span className="thq-link thq-body-small"><Link to="/online-courses" className="thq-link thq-body-small">{props.online}</Link></span>
              {/* <span className="thq-link thq-body-small">{props.profile}</span> */}
              <span className="thq-link thq-body-small"><button className="thq-button-outline" onClick={handleLogout}>Logout</button></span>
            </nav>
          </div>
          </div>
      </header>
    </div>
  )
}

TopNavbar.defaultProps = {
  books: 'Your Books',
  home: 'Home',
  imageAlt: 'image',
  online: 'Help',
  profile: 'Your Profile',
  logoSrc:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/84ec08e8-34e9-42c7-9445-d2806d156403/fac575ac-7a41-484f-b7ac-875042de11f8?org_if_sml=1&force_format=original',
  imageSrc: '/logo-transparent-png-200h.png',
  payment: 'Payment',
  writebook: 'Write a book',
  logoAlt: 'AI Book Writing',
  logout: 'Logout',
  rootClassName: '',
}

TopNavbar.propTypes = {
  books: PropTypes.string,
  home: PropTypes.string,
  imageAlt: PropTypes.string,
  online: PropTypes.string,
  profile: PropTypes.string,
  logoSrc: PropTypes.string,
  imageSrc: PropTypes.string,
  payment: PropTypes.string,
  writebook: PropTypes.string,
  logoAlt: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default TopNavbar
