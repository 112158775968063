import React from 'react'

import PropTypes from 'prop-types'

import './book-details-comp1.css'

const BookDetailsComp1 = (props) => {
  return (
    <div className="book-details-comp1-container thq-section-padding">
      <div className="book-details-comp1-max-width thq-section-max-width">
        <div className="book-details-comp1-content">
          <h2 className="book-details-comp1-heading1 thq-heading-2">
            {props.heading1}
          </h2>
          <p className="book-details-comp1-content1 thq-body-large">
            {props.content1}
          </p>
          <div className="book-details-comp1-actions">
            <button className="thq-button-filled book-details-comp1-button">
              <span className="book-details-comp1-action1 thq-body-small">
                {props.action1}
              </span>
            </button>
            <button className="thq-button-outline book-details-comp1-button1">
              <span className="book-details-comp1-action2 thq-body-small">
                {props.action2}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

BookDetailsComp1.defaultProps = {
  action1: 'Start Writing Now',
  heading1: 'Ready to Write Your Book?',
  content1:
    'Sign up today and experience the power of AI in writing your book effortlessly.',
  action2: 'Learn More',
}

BookDetailsComp1.propTypes = {
  action1: PropTypes.string,
  heading1: PropTypes.string,
  content1: PropTypes.string,
  action2: PropTypes.string,
}

export default BookDetailsComp1
