import React from 'react'

import { Helmet } from 'react-helmet'

import TopNavbar from '../components/top-navbar'
import ReadyToWriteBookComp from '../components/ready-to-write-book-comp'
import './ready-to-write-book.css'

const ReadyToWriteBook = (props) => {
  return (
    <div className="ready-to-write-book-container">
      <Helmet>
        <title>ReadyToWriteBook - Write your own book</title>
        <meta
          property="og:title"
          content="ReadyToWriteBook - Write your own book"
        />
      </Helmet>
      <TopNavbar rootClassName="top-navbar-root-class-name"></TopNavbar>
      <ReadyToWriteBookComp action1="Create Your Book"></ReadyToWriteBookComp>
    </div>
  )
}

export default ReadyToWriteBook
