import React from 'react'

import PropTypes from 'prop-types'

import './online-courses-comp2.css'

const OnlineCoursesComp2 = (props) => {
  return (
    <div className="online-courses-comp2-layout251 thq-section-padding">
      <div className="online-courses-comp2-max-width thq-section-max-width">
        <div className="thq-flex-row online-courses-comp2-section-title">
          <div className="online-courses-comp2-column thq-flex-column">
            <span className="thq-body-small">{props.slogan}</span>
            <h2 className="thq-heading-2 online-courses-comp2-text1">
              {props.sectionTitle}
            </h2>
          </div>
          <span className="thq-body-small">{props.sectionDescription}</span>
        </div>
        <div className="online-courses-comp2-content">
          <div className="online-courses-comp2-row thq-flex-row">
            <div className="online-courses-comp2-feature1 thq-flex-column">
              <img
                alt={props.feature1ImageAlt}
                src={props.feature1ImageSrc}
                className="thq-img-ratio-4-3 online-courses-comp2-feature1-image"
              />
              <div className="online-courses-comp2-content1 thq-flex-column">
                <h3 className="thq-heading-3">{props.feature1Title}</h3>
                <span className="thq-body-small">
                  {props.feature1Description}
                </span>
              </div>
            </div>
            <div className="online-courses-comp2-feature2 thq-flex-column">
              <img
                alt={props.feature2ImageAlt}
                src={props.feature2ImageSrc}
                className="thq-img-ratio-4-3 online-courses-comp2-feature2-image"
              />
              <div className="online-courses-comp2-content2 thq-flex-column">
                <h3 className="thq-heading-3">{props.feature2Title}</h3>
                <span className="thq-body-small">
                  {props.feature2Description}
                </span>
              </div>
            </div>
            <div className="online-courses-comp2-feature3 thq-flex-column">
              <img
                alt={props.feature3ImageAlt}
                src={props.feature3ImageSrc}
                className="thq-img-ratio-4-3 online-courses-comp2-feature3-image"
              />
              <div className="online-courses-comp2-content3 thq-flex-column">
                <h3 className="thq-heading-3">{props.feature3Title}</h3>
                <span className="thq-body-small">
                  {props.feature3Description}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="online-courses-comp2-actions">
          <button className="thq-button-filled online-courses-comp2-button">
            <span className="thq-body-small">{props.mainAction}</span>
          </button>
          <button className="thq-button-outline online-courses-comp2-button1">
            <span className="thq-body-small">{props.secondaryAction}</span>
          </button>
        </div>
      </div>
    </div>
  )
}

OnlineCoursesComp2.defaultProps = {
  feature3Title: 'Download in Various Formats',
  feature2ImageAlt: 'Generative AI Image',
  secondaryAction: 'Grammar Checks',
  sectionDescription:
    'Explore the powerful features of our AI-powered book writing platform.',
  feature3ImageAlt: 'Download Formats Image',
  mainAction: 'Personalized Writing Suggestions',
  feature2Title: 'Cutting-Edge Generative AI',
  feature2Description:
    'Automate your writing process with advanced AI technology.',
  slogan: 'Empowering Authors with AI Technology',
  feature3Description:
    'Get your completed book in the format of your choice securely.',
  feature1ImageAlt: 'Content Recommendations Image',
  sectionTitle: 'Features',
  feature1Title: 'Content Recommendations',
  feature1Description: 'Receive tailored suggestions to enhance your writing.',
  feature2ImageSrc:
    'https://images.unsplash.com/photo-1482059387495-939d52279925?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxNjk4MzU5Nnw&ixlib=rb-4.0.3&q=80&w=1080',
  feature1ImageSrc:
    'https://images.unsplash.com/photo-1548191194-b3d4f051fd7d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxNjk4MzU5N3w&ixlib=rb-4.0.3&q=80&w=1080',
  feature3ImageSrc:
    'https://images.unsplash.com/photo-1667986292516-f27450ae75a9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxNjk4MzU5N3w&ixlib=rb-4.0.3&q=80&w=1080',
}

OnlineCoursesComp2.propTypes = {
  feature3Title: PropTypes.string,
  feature2ImageAlt: PropTypes.string,
  secondaryAction: PropTypes.string,
  sectionDescription: PropTypes.string,
  feature3ImageAlt: PropTypes.string,
  mainAction: PropTypes.string,
  feature2Title: PropTypes.string,
  feature2Description: PropTypes.string,
  slogan: PropTypes.string,
  feature3Description: PropTypes.string,
  feature1ImageAlt: PropTypes.string,
  sectionTitle: PropTypes.string,
  feature1Title: PropTypes.string,
  feature1Description: PropTypes.string,
  feature2ImageSrc: PropTypes.string,
  feature1ImageSrc: PropTypes.string,
  feature3ImageSrc: PropTypes.string,
}

export default OnlineCoursesComp2
