import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import './online-courses-comp1.css'

const OnlineCoursesComp1 = (props) => {
  return (
    <div className="online-courses-comp1-header30 thq-section-padding">
      <div style={{ width: '80vw', height:'100vh'}}>
        <iframe src="https://bookbuilder.pro/help.html" title="Help" width="100%" height="100%" />
      </div>
    </div>
  )
}

OnlineCoursesComp1.defaultProps = {
  content1:
    'Experience the power of AI in writing your book with personalized suggestions, grammar checks, and content recommendations.',
  heading1: 'Unleash Your Writing Potential with AI',
  image1Alt: 'AI-powered Book Writing Platform',
  action1: 'Learn More',
  action2: 'Sign Up Now',
  image1Src:
    'https://images.unsplash.com/photo-1486611367184-17759508999c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxNjk4MzU5Nnw&ixlib=rb-4.0.3&q=80&w=1080',
}

OnlineCoursesComp1.propTypes = {
  content1: PropTypes.string,
  heading1: PropTypes.string,
  image1Alt: PropTypes.string,
  action1: PropTypes.string,
  action2: PropTypes.string,
  image1Src: PropTypes.string,
}

export default OnlineCoursesComp1
