import React, { useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, updateProfile, onAuthStateChanged } from 'firebase/auth';
//import './sign-in.css'; // Import your CSS for styling
import './sign-in.css';
import { registerWithEmailAndPassword } from '../firebase';
import { TextField, Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { logout } from '../firebase';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_YOUR_API_KEY,
  authDomain: process.env.REACT_APP_YOUR_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_YOUR_PROJECT_ID,
  storageBucket: process.env.REACT_APP_YOUR_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_YOUR_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_YOUR_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

function Signup(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // New state for confirm password
  const [displayName, setDisplayName] = useState('');
  const [error, setError] = useState(null);

  const handleSignup = async (e) => {
    e.preventDefault();
    setError(null);

    // Password validation
    if (password !== confirmPassword) {
      setError('Passwords do not match!');
      return;
    }

    // Basic password strength check (you can customize this further)
    if (password.length < 8) {
      setError('Password must be at least 8 characters long.');
      return;
    }

    try {
      const userCredential = await registerWithEmailAndPassword(email, password);

      if (userCredential && userCredential.user) {
        const user = userCredential.user;
        await updateProfile(user, { displayName });
        //sessionStorage.setItem('userid', user.uid);
        //sessionStorage.setItem('token', "valid");
        // Send verification email
        await sendEmailVerification(user, {
          url: 'http://localhost:3000/?signupSuccess=true', // Replace with your app's URL
        });
        setError('Verification email sent! Please check your inbox.');
      }
      await logout();
      // Redirect to login page or do something else on logout
      //setUserid(None);
      sessionStorage.removeItem('userid'); // Add userid to sessionStorage
      sessionStorage.removeItem('token'); 
      window.location.href = '/?signupSuccess=true'; 
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setError('Email address is already in use.');
      } else if (error.code === 'auth/weak-password') {
        setError('Password is too weak. Please choose a stronger password.');
      } else {
        setError('An error occurred during signup. Please try again.');
        //console.error(error); // Log the error for debugging
      }
    }
  };

  // Function to handle button click
  const handleSignupClick = (e) => {
    e.preventDefault(); // Prevent default form submission
    handleSignup(e); // Call the actual signup function
  };

  const handleSigninClick = () => {
    window.location.href = '/';
  };

  return (
    <div className="signup-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}> 
      <div className="sign-in-form">
        <form className="sign-in-form1">
          {/* <Box 
            component="form"
            className="sign-in-form1"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' }, 
              display: 'flex', 
              flexDirection: 'column',
              alignItems: 'center', 
              maxWidth: 400, // Set a max width for the form
            }}
            noValidate
            autoComplete="off"
          > */}
            <div className="sign-in-form1" style={{ textAlign: 'center', width: '100%' }}> {/* Center text within the form */}
                <h2 className="sign-in-text thq-heading-2" style={{ fontSize: '2rem' }}> {/* Adjust font size */}
                {props.heading2}
              </h2>

              {error && <p className="error-message">{error}</p>}

              <div className="sign-in-email">
                <label
                  htmlFor="thq-sign-in-4-password"
                  className="thq-body-large"
                >
                  Display Name
                </label>
                <input
                  type="email"
                  id="displayName"
                  value={displayName}
                  onChange={(e) => setDisplayName(e.target.value)}
                  required
                  fullWidth // Make input fields take full width
                  className="sign-in-textinput thq-body-large thq-input"
                />
              </div>
              <div className="sign-in-email">
                <label
                    htmlFor="thq-sign-in-4-password"
                    className="thq-body-large"
                  >
                    Email
                  </label>
                <input
                  id="email"
                  label="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  fullWidth
                  className="sign-in-textinput thq-body-large thq-input"
                />
              </div>
              <div className="sign-in-email">
                <label
                  htmlFor="thq-sign-in-4-password"
                  className="thq-body-large"
                >
                  Password
                </label>
                <input
                  type="password"
                  label="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  fullWidth
                  className="sign-in-textinput1 thq-body-large thq-input"
                />
              </div>
              <div className="sign-in-email"> {/* New confirm password field */}
              <label
                  htmlFor="thq-sign-in-4-password"
                  className="thq-body-large"
                >
                  Confirm Password
                </label>
                <input
                  type="password"
                  id="confirmPassword"
                  label="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  fullWidth
                  className="sign-in-textinput1 thq-body-large thq-input"
                />
              </div>
            
              {/* <Button 
                type="submit"
                variant="contained" 
                onClick={handleSignupClick} 
                className="sign-in-button2 thq-button-filled"
                style={{ width: '100%' }} // Make button full width
              >
                Register
              </Button> */}

              <div className="sign-in-container5">
                <button
                  type="submit"
                  className="sign-in-button2 thq-button-filled" onClick={handleSignupClick}
                >
                  <span className="sign-in-text09 thq-body-small">
                    Register
                  </span>
                </button>
              </div>
            </div>
          {/* </Box> */}
        </form>
      </div>
      <div className="sign-in-divider" style={{ width: '100%', maxWidth: 400, margin: '20px auto' }}> 
        <div className="sign-in-divider1"></div>
          <p className="thq-body-large">OR</p>
        <div className="sign-in-divider2"></div>
      </div>
      <div className="sign-in-container1" style={{ textAlign: 'center', maxWidth: 400 }}> {/* Limit width of sign in section */}
        <h2 className="sign-in-text thq-heading-2" style={{ fontSize: '1.2rem' }}> {/* Adjust font size */}
          {props.heading1}
        </h2>
        <button type="button" 
          className="sign-in-button2 thq-button-filled" 
          onClick={handleSigninClick}
          style={{ width: '100%' }} // Make button full width
        >
          <span className="sign-in-text09 thq-body-small">
            Login
          </span>
        </button>
      </div>
    </div>
  );
}

Signup.defaultProps = {
  heading1: 'Already have an account? Login to continue writing your book',
  heading2: 'Register',
};

Signup.propTypes = {
  heading1: PropTypes.string,
};

export default Signup;
