import React from 'react'

import PropTypes from 'prop-types'

import './chapter-proofing-comp1.css'

const ChapterProofingComp1 = (props) => {
  return (
    <div className="chapter-proofing-comp1-container thq-section-padding">
      <div className="chapter-proofing-comp1-max-width thq-section-max-width">
        <div className="chapter-proofing-comp1-content">
          <h2 className="chapter-proofing-comp1-heading1 thq-heading-2">
            {props.heading1}
          </h2>
          <p className="chapter-proofing-comp1-content1 thq-body-large">
            {props.content1}
          </p>
          <div className="chapter-proofing-comp1-actions">
            <button className="thq-button-filled chapter-proofing-comp1-button">
              <span className="chapter-proofing-comp1-action1 thq-body-small">
                {props.action1}
              </span>
            </button>
            <button className="thq-button-outline chapter-proofing-comp1-button1">
              <span className="chapter-proofing-comp1-action2 thq-body-small">
                {props.action2}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

ChapterProofingComp1.defaultProps = {
  action1: 'Main action',
  heading1: 'Call to action that excites the visitor to try your product',
  content1:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.',
  action2: 'Secondary action',
}

ChapterProofingComp1.propTypes = {
  action1: PropTypes.string,
  heading1: PropTypes.string,
  content1: PropTypes.string,
  action2: PropTypes.string,
}

export default ChapterProofingComp1
