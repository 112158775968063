import React from 'react'

import { Helmet } from 'react-helmet'

import TopNavbar from '../components/top-navbar'
import Privacy1 from '../components/privacy1'
import Footer from '../components/footer'
import './privacy.css'

const Privacy = (props) => {
  return (
    <div className="privacy-container">
      <Helmet>
        <title>Privacy - Write your own book</title>
        <meta property="og:title" content="Privacy - Write your own book" />
      </Helmet>
      <div className="privacy-navbar1">
        <TopNavbar></TopNavbar>
      </div>
      <div className="privacy-cta2"></div>
      <div className="privacy-contentlist3">
        <Privacy1></Privacy1>
      </div>
      <div className="privacy-footer11">
        <Footer></Footer>
      </div>
    </div>
  )
}

export default Privacy
