import React from 'react'

import { Helmet } from 'react-helmet'

import LoginNavbar from '../components/login-navbar'
import SignUp from '../components/signup'
import Footer from '../components/footer'
import './login.css'

const SignupPage = (props) => {
  return (
    <div className="login-container">
      <Helmet>
        <title>Login - Write your own book</title>
        <meta property="og:title" content="Login - Write your own book" />
      </Helmet>
      {/* <LoginNavbar rootClassName="login-navbar-root-class-name"></LoginNavbar> */}
      <SignUp></SignUp>
      <Footer rootClassName="footer-root-class-name"></Footer>
    </div>
  )
}

export default SignupPage
