import React from 'react'

import PropTypes from 'prop-types'

import './book-proof-reading-comp2.css'

const BookProofReadingComp2 = (props) => {
  return (
    <div className="book-proof-reading-comp2-container thq-section-padding">
      <div className="book-proof-reading-comp2-max-width thq-section-max-width">
        <div className="book-proof-reading-comp2-content thq-flex-column">
          <ul className="thq-flex-column">
            <li className="list-item thq-flex-column">
              <h2 className="book-proof-reading-comp2-heading1 thq-heading-2">
                {props.heading1}
              </h2>
              <p className="thq-body-small">{props.content1}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading2}</h3>
              <p className="thq-body-small">{props.content2}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading3}</h3>
              <p className="thq-body-small">{props.content3}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading4}</h3>
              <p className="thq-body-small">{props.content4}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading5}</h3>
              <p className="thq-body-small">{props.content5}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading6}</h3>
              <p className="thq-body-small">{props.content6}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

BookProofReadingComp2.defaultProps = {
  content4:
    "We prioritize the security and confidentiality of the content created on our platform, ensuring that authors' work is protected at all times.",
  content3:
    'Authors have the option to choose fully automated writing using AI, streamlining the writing process and saving time.',
  content6:
    'Our AI-powered platform makes book crafting effortless, allowing authors to focus on their creativity while receiving valuable assistance from our technology.',
  heading5: 'Download in Various Formats',
  content1:
    'Our platform utilizes advanced AI algorithms, Google Gemini AI, and Generative AI technology to assist authors in writing their books with ease.',
  content2:
    'Authors receive personalized suggestions, grammar checks, and content recommendations to enhance their writing and improve the overall quality of their books.',
  heading4: 'Security and Confidentiality',
  content5:
    'Authors can download their completed books in various formats suitable for publishing or personal use, providing flexibility and convenience.',
  heading1: 'AI-Powered Book Writing Platform',
  heading3: 'Fully Automated Writing Option',
  heading6: 'Effortless Book Crafting',
  heading2: 'Personalized Suggestions and Grammar Checks',
}

BookProofReadingComp2.propTypes = {
  content4: PropTypes.string,
  content3: PropTypes.string,
  content6: PropTypes.string,
  heading5: PropTypes.string,
  content1: PropTypes.string,
  content2: PropTypes.string,
  heading4: PropTypes.string,
  content5: PropTypes.string,
  heading1: PropTypes.string,
  heading3: PropTypes.string,
  heading6: PropTypes.string,
  heading2: PropTypes.string,
}

export default BookProofReadingComp2
