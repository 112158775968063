import React from 'react'

import { Helmet } from 'react-helmet'

import TopNavbar from '../components/top-navbar'
import CookiesComp1 from '../components/cookies-comp1'
import CookiesComp2 from '../components/cookies-comp2'
import Footer from '../components/footer'
import './cookies.css'

const Cookies = (props) => {
  return (
    <div className="cookies-container">
      <Helmet>
        <title>Cookies - Write your own book</title>
        <meta property="og:title" content="Cookies - Write your own book" />
      </Helmet>
      <div className="cookies-navbar1">
        <TopNavbar></TopNavbar>
      </div>
      <div className="cookies-cta2">
        <CookiesComp1></CookiesComp1>
      </div>
      <div className="cookies-footer11">
        <Footer></Footer>
      </div>
    </div>
  )
}

export default Cookies
