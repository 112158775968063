import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './sign-in.css';
import axios from 'axios';
import Signup from './signup'; // Import Signup component
//import { getAuth, signInWithEmailAndPassword, onAuthStateChanged  } from 'firebase/auth';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
//import { auth } from './firebaseConfig';
//import { auth } from '../firebase';
import { Link } from 'react-router-dom'; // Import Link at the top
import { 
  getAuth,
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect, 
  onAuthStateChanged,
  GoogleAuthProvider,
  GithubAuthProvider,
  sendPasswordResetEmail,
  getRedirectResult,
  signOut 
} from 'firebase/auth';
import {ReactComponent as GoogleSvg} from './google.svg'; // Assuming your SVG is in the 'images' folder


const SignIn = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [userid, setUserid] = useState(null);

  // State to manage the signup success message
  const [showSignupSuccess, setShowSignupSuccess] = useState(false);

  // Check if redirected from signup with a success message
  useEffect(() => {
    const handleQueryParamsChange = () => {
      const queryParams = new URLSearchParams(window.location.search);
      const signupSuccess = queryParams.get('signupSuccess') === 'true';
  
      if (signupSuccess) {
        setShowSignupSuccess(true);
        // Clear the signupSuccess query parameter from the URL
        queryParams.delete('signupSuccess');
        window.history.replaceState({}, document.title, `<span class="math-inline">\{window\.location\.pathname\}?</span>{queryParams.toString()}`);
      }
    };
  
    // Initial check on component mount
    handleQueryParamsChange();
  
    // Listen for changes in the URL's query parameters
    window.addEventListener('popstate', handleQueryParamsChange);
  
    return () => {
      // Clean up the event listener on component unmount
      window.removeEventListener('popstate', handleQueryParamsChange);
    };
  }, []);
  
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_YOUR_API_KEY,
    authDomain: process.env.REACT_APP_YOUR_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_YOUR_PROJECT_ID,
    storageBucket: process.env.REACT_APP_YOUR_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_YOUR_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_YOUR_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  //const analytics = getAnalytics(app);
  const auth = getAuth(app); // Get the Auth instance here

  //const navigate = useNavigate();

  const handleSignupClick = () => {
    window.location.href = '/signup';
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    // Initialize Firebase Auth provider
      
    // whenever a user interacts with the provider, we force them to select an account
    provider.setCustomParameters({   
        prompt : "select_account "
    });
    //const signInWithGooglePopup = () => signInWithPopup(auth, provider);
    console.log("Provider:"+provider)
    try {
      //await signInWithRedirect(auth, provider);
      await signInWithPopup(auth, provider);
      console.log("signInWithRedirect initiated");
      //window.location.href = '/book-details';

      // After redirect, get the user:
      getRedirectResult(auth)
        .then((result) => {
          console.log("result.user:"+result.user);

          if (result.user) {
            // User is signed in
            const user = result.user;
            console.log("User signed in:", user);
            // Redirect or perform actions on successful login
            sessionStorage.setItem('token', "valid"); 
            sessionStorage.setItem('user', user); 
            window.location.href = '/book-details'; 
          }
        })
        .catch((error) => {
          console.error("Error signing in with Google:", error);
        });
    } catch (error) {
      // Handle Firebase authentication errors, including errors specific to signInWithRedirect
      if (error.code === 'auth/account-exists-with-different-credential') {
        setErrorMessage('An account already exists with the same email address but different credentials. Please sign in with that provider.');
      } else if (error.code === 'auth/popup-closed-by-user') {
        setErrorMessage('Sign-in window was closed by the user.');
      } else {
        setErrorMessage('An error occurred during sign-in. Please try again later.');
      }
      console.error(error);
    }
  };

  onAuthStateChanged(auth, (user) => {
    // Check if redirected from signup with a success message
    const queryParams = new URLSearchParams(window.location.search);
    const signupSuccess = queryParams.get('signupSuccess') === 'true';
    
    if (user && !signupSuccess) { // Only redirect if not showing signup success
      // User is signed in, get the user ID
      setUserid(user.uid);
      sessionStorage.setItem('userid', user.uid); // Add userid to sessionStorage
      sessionStorage.setItem('token', "valid"); 
      window.location.href = '/book-details';
      //console.log("userid from sign-in:"+userid)
    } else {
        setUserid(null); // Set userid from sessionStorage
        sessionStorage.removeItem('userid'); // Remove userid from sessionStorage
    }
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage(null); // Clear any previous error messages
    setShowSignupSuccess(false);

    try {
      await signInWithEmailAndPassword(auth, email, password);
      sessionStorage.setItem('token', "valid"); 
      window.location.href = '/book-details'; 
    } catch (error) {
      // Handle Firebase authentication errors here
      if (error.code === 'auth/invalid-email' || error.code === 'auth/wrong-password') {
        setErrorMessage('Invalid email or password.');
      } else if (error.code === 'auth/user-not-found') {
        setErrorMessage('User not found. Please sign up.');
      } else {
        setErrorMessage('An error occurred during sign-in. Please try again later.');
      }
      console.error(error); 
    }
  };

  return (
    <div className="sign-in-container thq-section-padding">
      <div className="sign-in-max-width thq-section-max-width thq-section-padding">
        <div className="sign-in-form-root">
          <div className="sign-in-form">
            {showSignupSuccess && (
              <p className="error-message" style={{ color: 'red' }}>Signup successful! Please log in to continue.</p>
            )} 
              

              <div className="sign-in-container1">
                <h2 className="sign-in-text thq-heading-2">{props.heading1}</h2>
                <form className="sign-in-form1">
                  <div className="sign-in-email">
                    <label htmlFor="thq-sign-in-4-email" className="thq-body-large">
                      Email
                    </label>
                    <input
                      type="email"
                      id="thq-sign-in-4-email"
                      required={true} // Change from required="true"
                      placeholder="Email address"
                      className="sign-in-textinput thq-body-large thq-input"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="sign-in-password">
                    <div className="sign-in-container3">
                      <label
                        htmlFor="thq-sign-in-4-password"
                        className="thq-body-large"
                      >
                        Password
                      </label>
                      <div className="sign-in-hide-password">
                        <svg viewBox="0 0 1024 1024" className="sign-in-icon4">
                          <path d="M317.143 762.857l44.571-80.571c-66.286-48-105.714-125.143-105.714-206.857 0-45.143 12-89.714 34.857-128.571-89.143 45.714-163.429 117.714-217.714 201.714 59.429 92 143.429 169.143 244 214.286zM539.429 329.143c0-14.857-12.571-27.429-27.429-27.429-95.429 0-173.714 78.286-173.714 173.714 0 14.857 12.571 27.429 27.429 27.429s27.429-12.571 27.429-27.429c0-65.714 53.714-118.857 118.857-118.857 14.857 0 27.429-12.571 27.429-27.429zM746.857 220c0 1.143 0 4-0.571 5.143-120.571 215.429-240 432-360.571 647.429l-28 50.857c-3.429 5.714-9.714 9.143-16 9.143-10.286 0-64.571-33.143-76.571-40-5.714-3.429-9.143-9.143-9.143-16 0-9.143 19.429-40 25.143-49.714-110.857-50.286-204-136-269.714-238.857-7.429-11.429-11.429-25.143-11.429-39.429 0-13.714 4-28 11.429-39.429 113.143-173.714 289.714-289.714 500.571-289.714 34.286 0 69.143 3.429 102.857 9.714l30.857-55.429c3.429-5.714 9.143-9.143 16-9.143 10.286 0 64 33.143 76 40 5.714 3.429 9.143 9.143 9.143 15.429zM768 475.429c0 106.286-65.714 201.143-164.571 238.857l160-286.857c2.857 16 4.571 32 4.571 48zM1024 548.571c0 14.857-4 26.857-11.429 39.429-17.714 29.143-40 57.143-62.286 82.857-112 128.571-266.286 206.857-438.286 206.857l42.286-75.429c166.286-14.286 307.429-115.429 396.571-253.714-42.286-65.714-96.571-123.429-161.143-168l36-64c70.857 47.429 142.286 118.857 186.857 192.571 7.429 12.571 11.429 24.571 11.429 39.429z"></path>
                        </svg>
                        <span className="thq-body-small">Hide</span>
                      </div>
                    </div>
                    <input
                      type="password"
                      id="thq-sign-in-4-password"
                      required={true} // Change from required="true"
                      placeholder="Password"
                      className="sign-in-textinput1 thq-body-large thq-input"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className="sign-in-container4">
                      <Link to="/forgot-password" className="sign-in-text08 thq-body-small">
                        Forgot password
                      </Link>
                    </div>
                  </div>
                  {errorMessage && (
                    <p className="error-message" style={{ color: 'red' }}>{errorMessage}</p>
                  )}
                </form>
              <div className="sign-in-container5">
                <button
                  type="submit"
                  className="sign-in-button2 thq-button-filled" onClick={handleSubmit}
                >
                  <span className="sign-in-text09 thq-body-small">
                    {props.action1}
                  </span>
                </button>
              </div>
            </div>

            <div className="sign-in-container2">
              { <button className="sign-in-button thq-button-outline" onClick={handleGoogleSignIn}>
                {/* <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="sign-in-icon"
                >
                  <path d="M713.143 73.143c90.857 0 164.571 73.714 164.571 164.571v548.571c0 90.857-73.714 164.571-164.571 164.571h-107.429v-340h113.714l17.143-132.571h-130.857v-84.571c0-38.286 10.286-64 65.714-64l69.714-0.571v-118.286c-12-1.714-53.714-5.143-101.714-5.143-101.143 0-170.857 61.714-170.857 174.857v97.714h-114.286v132.571h114.286v340h-304c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571z"></path>
                </svg> */}
                <GoogleSvg className="sign-in-icon" /> 
                <span className="thq-body-small">Continue with Google</span>
              </button>
              /*<button className="sign-in-button1 thq-button-outline">
                <svg
                  viewBox="0 0 860.0137142857142 1024"
                  className="sign-in-icon2"
                >
                  <path d="M438.857 449.143h414.286c4 22.286 6.857 44 6.857 73.143 0 250.286-168 428.571-421.143 428.571-242.857 0-438.857-196-438.857-438.857s196-438.857 438.857-438.857c118.286 0 217.714 43.429 294.286 114.857l-119.429 114.857c-32.571-31.429-89.714-68-174.857-68-149.714 0-272 124-272 277.143s122.286 277.143 272 277.143c173.714 0 238.857-124.571 249.143-189.143h-249.143v-150.857z"></path>
                </svg>
                <span className="thq-body-small">Continue with Github</span>
              </button> */}
            </div>
            
            <div className="sign-in-divider">
              <div className="sign-in-divider1"></div>
              <p className="thq-body-large">OR</p>
              <div className="sign-in-divider2"></div>
            </div>
            <h2 className="sign-in-text thq-heading-2">{props.heading2}</h2>
            {/* <p className="sign-in-text01 thq-body-large">
                Don&apos;t have an account? 
                <Link to="/signup" className="sign-up-link">  Sign Up</Link> 
                </p> */}
              <button type="button" // Change type to "button"
                className="sign-in-button2 thq-button-filled" onClick={handleSignupClick}
                >
                <span className="sign-in-text09 thq-body-small">
                  Register Now
                </span>
              </button>
          </div>
        </div>
      </div>
      <img
        alt={props.image1Alt}
        src={props.image1Src}
        className="sign-in-sign-up-image thq-img-ratio-16-9"
      />
      <div className="sign-in-container6"></div>
      {showSignupModal && <Signup onClose={() => setShowSignupModal(false)} />}
    </div>
  );
};

SignIn.defaultProps = {
  image1Src:
    'https://images.unsplash.com/photo-1555601568-c9e6f328489b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxNjc5MzY0NXw&ixlib=rb-4.0.3&q=80&w=1080',
  heading1: 'Welcome back! Login to continue writing your book',
  heading2: 'Not registered yet. Register now',
  action1: 'Login',
  image1Alt: 'AI-powered book writing platform',
};

SignIn.propTypes = {
  image1Src: PropTypes.string,
  heading1: PropTypes.string,
  action1: PropTypes.string,
  image1Alt: PropTypes.string,
};

export default SignIn;
