import React from 'react'

import PropTypes from 'prop-types'

import './home-gemini-comp.css'

const HomeGeminiComp = (props) => {
  return (
    <div className="home-gemini-comp-gallery3 thq-section-padding">
      <div className="home-gemini-comp-max-width thq-section-max-width">
        <div className="home-gemini-comp-section-title">
          <h2 className="home-gemini-comp-text thq-heading-2">
            {props.heading1}
          </h2>
          <span className="home-gemini-comp-text1 thq-body-large">
            {props.content1}
          </span>
        </div>
        <img
          alt={props.imageAlt}
          src={props.imageSrc}
          className="home-gemini-comp-image"
        />
        <div className="home-gemini-comp-content">
          <div className="home-gemini-comp-container"></div>
          <div className="home-gemini-comp-container1"></div>
          <div className="home-gemini-comp-container2"></div>
        </div>
      </div>
    </div>
  )
}

HomeGeminiComp.defaultProps = {
  image1Src:
    'https://images.unsplash.com/photo-1639815188508-13f7370f664a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxNjc3NTQ0N3w&ixlib=rb-4.0.3&q=80&w=1080',
  image3Alt: 'Author interviews',
  heading1: 'Powered by Google Gemini AI',
  image3Src:
    'https://images.unsplash.com/photo-1606166325695-ce4d64e3195f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxNjc3NTQ0Nnw&ixlib=rb-4.0.3&q=80&w=1080',
  content1: '',
  imageAlt: 'image',
  image2Src:
    'https://images.unsplash.com/photo-1517145598654-91f0bb341394?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxNjc3NTQ0N3w&ixlib=rb-4.0.3&q=80&w=1080',
  imageSrc: '/gemini-models-400h.png',
  image1Alt: 'Writing tools',
  image2Alt: 'Book cover designs',
}

HomeGeminiComp.propTypes = {
  image1Src: PropTypes.string,
  image3Alt: PropTypes.string,
  heading1: PropTypes.string,
  image3Src: PropTypes.string,
  content1: PropTypes.string,
  imageAlt: PropTypes.string,
  image2Src: PropTypes.string,
  imageSrc: PropTypes.string,
  image1Alt: PropTypes.string,
  image2Alt: PropTypes.string,
}

export default HomeGeminiComp
