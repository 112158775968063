// book-page.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; 
import { Button, CircularProgress, Typography } from '@mui/material'; 
import dompurify from 'dompurify';
import { Alert } from '@mui/material'; 
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_YOUR_API_KEY,
    authDomain: process.env.REACT_APP_YOUR_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_YOUR_PROJECT_ID,
    storageBucket: process.env.REACT_APP_YOUR_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_YOUR_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_YOUR_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };

const BookPage = () => {
    const { bookId } = useParams(); 
    const [bookData, setBookData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isDownloading, setIsDownloading] = useState(false);
    // Add new state to track download status
    const [downloadingBookId, setDownloadingBookId] = useState(null);
    const [downloadMessage, setDownloadMessage] = useState(null);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const [fullText, setFullText] = useState('');
    const [bookTitle, setBookTitle] = useState('');
    const [user, setUser] = useState(null); // Add user state

    useEffect(() => {
        const app = initializeApp(firebaseConfig);
        const auth = getAuth(app);

        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
            } else {
                // Redirect to login if not logged in
                // You might want to redirect to a specific login page
                window.location.href = '/'; 
            }
        });

        // Clean up the listener on component unmount
        return () => unsubscribe();
    }, []);
    
    useEffect(() => {
        const fetchBookDetails = async () => {
            try {
                console.log("Extracted bookId fetchBookDetails:", bookId); // This will log "1726283249942-626537"
                const response = await fetch(process.env.REACT_APP_DOWNLOAD_BOOK, {
                    method: 'POST',
                    body: JSON.stringify({
                    bookid: bookId
                    }),
                    headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'Authorization': 'Bearer ' + process.env.API_KEY,
                    },
                });
            
                if (!response.ok) {
                    throw new Error('Network response was not ok.');
                }
            
                const data = await response.json();
            
                // Check if the logged-in user's ID matches the book's owner ID
                if (user && data.userid === user.uid) { 
                    const sanitizedFirstChapter = dompurify.sanitize(data.fullText.split('\n').join(''));
                    setFullText(sanitizedFirstChapter);
                    setBookTitle(data.booktitle);
                    setBookData(data); // Store the entire book data
                    return data;
                } else {
                    setError('You do not have access to this book.');
                }
            } catch (err) {
                console.error('Error fetching book proof:', err);
                setError('An error occurred while fetching the book proof.'); 
            } finally {
                setIsLoading(false);
            }
        };
        
        if (user && bookId) { // Only fetch if user is logged in and bookId is available
            fetchBookDetails();
        }
    }, [bookId, user]);

    const fetchBookDetails = async (bookId) => {
        try {
            const response = await fetch(process.env.REACT_APP_DOWNLOAD_BOOK, {
                method: 'POST',
                body: JSON.stringify({
                bookid: bookId
                }),
                headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + process.env.API_KEY,
                },
            });
        
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
        
            const data = await response.json();
        
            // Sanitize and set state directly in getBookProof
            //const sanitizedFirstChapter = dompurify.sanitize(data.fullText.split('\n').join(''));
            //setFullText(sanitizedFirstChapter); // Set the fullText state here
            //setBookTitle(data.booktitle); 
        
            return data;
    
        } catch (err) {
            console.error('Error fetching book proof:', err);
            setError('An error occurred while fetching the book proof.'); 
        } finally {
            setIsLoading(false);
        }
    };

    const handleDownload = async () => {
        setMessage('Please wait, your book is being downloaded... It may take up to 1 minute for the book to download. Please make sure your browser allows file downloads.');
        setIsDownloading(true);
        setError(null);

        try {
            //bookData = await fetchBookDetails(bookid); 
            // Simulate a delay (replace with your actual logic)
            //await new Promise(resolve => setTimeout(resolve, 5000)); // Wait for 1 second
      
            const response = await fetch(process.env.REACT_APP_GENERATE_PDF, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ 
                  html: fullText,  
                  booktitle: bookTitle  
              }),
            });
      
            if (response.ok) {
              const blob = await response.blob();
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', bookTitle + ".pdf");
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              setMessage('Your book has been downloaded and can be found in the download folder of your computer'); // Set message
            } else {
              console.error('Error generating PDF:', response.status);
              setError('An error occurred while generating the PDF.');
            }
        } catch (err) {
            console.error('Error generating PDF:', err);
            setError('An error occurred while generating the PDF.');
        } finally {
            setIsLoading(false);
            setIsDownloading(false);
            setDownloadingBookId(null); 
        }
    };

    if (isLoading) {
        return <div>Loading...</div>; 
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (bookTitle && fullText) {
        return (
            <div>
                {message && (
                    <Alert severity="info" sx={{ m: 2 }}>
                    {message}
                    </Alert>
                )}
                <h1>{bookTitle}</h1>
                {/* <div dangerouslySetInnerHTML={{ __html: dompurify.sanitize(fullText) }} /> */}

                {isDownloading && (
                    <Typography variant="body2">{message}</Typography> 
                )}

                {!isDownloading && (
                    <Button onClick={handleDownload} disabled={isDownloading}>
                        Download PDF
                    </Button>
                )}
            </div>
        );
    } else {
        return <div>Book not found.</div>; // Or handle the error gracefully
    }
};

export default BookPage;