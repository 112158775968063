import React from 'react'

import PropTypes from 'prop-types'

import './cta15.css'

const CTA15 = (props) => {
  return (
    <div className="cta15-container thq-section-padding">
      <div className="cta15-max-width thq-section-max-width">
        <div className="cta15-content">
          <h2 className="cta15-heading1 thq-heading-2">{props.heading1}</h2>
          <p className="cta15-content1 thq-body-large">{props.content1}</p>
          <div className="cta15-actions">
            <button className="thq-button-filled cta15-button">
              <span className="cta15-action1 thq-body-small">
                {props.action1}
              </span>
            </button>
            <button className="thq-button-outline cta15-button1">
              <span className="cta15-action2 thq-body-small">
                {props.action2}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

CTA15.defaultProps = {
  action1: 'Start Writing Now',
  heading1: 'Unlock the Power of AI for Your Book Writing',
  content1:
    'Experience the future of book writing with our AI-powered platform. Get automated writing assistance, personalized suggestions, grammar checks, and more.',
  action2: 'Learn More',
}

CTA15.propTypes = {
  action1: PropTypes.string,
  heading1: PropTypes.string,
  content1: PropTypes.string,
  action2: PropTypes.string,
}

export default CTA15
