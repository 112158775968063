import React from 'react'

import PropTypes from 'prop-types'

import './payment-comp2.css'

const PaymentComp2 = (props) => {
  return (
    <div className="payment-comp2-container thq-section-padding">
      <div className="payment-comp2-max-width thq-section-max-width">
        <div className="payment-comp2-content thq-flex-column">
          <ul className="thq-flex-column">
            <li className="list-item thq-flex-column">
              <h2 className="payment-comp2-heading1 thq-heading-2">
                {props.heading1}
              </h2>
              <p className="thq-body-small">{props.content1}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading2}</h3>
              <p className="thq-body-small">{props.content2}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading3}</h3>
              <p className="thq-body-small">{props.content3}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading4}</h3>
              <p className="thq-body-small">{props.content4}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading5}</h3>
              <p className="thq-body-small">{props.content5}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading6}</h3>
              <p className="thq-body-small">{props.content6}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

PaymentComp2.defaultProps = {
  content4:
    'Our platform utilizes Google Gemini AI to provide cutting-edge solutions for plot development suggestions and secure content storage.',
  content3:
    'Choose to write your book with AI assistance or fully automate the writing process based on your preferences.',
  content6:
    'Join our platform now to unleash the power of AI in writing and bring your book ideas to life.',
  heading5: 'Support and FAQs',
  content1:
    'Experience the future of book writing with our advanced AI algorithms that assist authors in creating their masterpieces.',
  content2:
    'Get writing suggestions, grammar checks, and content recommendations to enhance your writing process.',
  heading4: 'Google Gemini AI',
  content5:
    'Access our FAQs section, contact support, and kickstart your book writing journey with confidence.',
  heading1: 'AI-Powered Book Writing Platform',
  heading3: 'AI Assistance or Full Automation',
  heading6: 'Start Writing Your Book Today',
  heading2: 'Features',
}

PaymentComp2.propTypes = {
  content4: PropTypes.string,
  content3: PropTypes.string,
  content6: PropTypes.string,
  heading5: PropTypes.string,
  content1: PropTypes.string,
  content2: PropTypes.string,
  heading4: PropTypes.string,
  content5: PropTypes.string,
  heading1: PropTypes.string,
  heading3: PropTypes.string,
  heading6: PropTypes.string,
  heading2: PropTypes.string,
}

export default PaymentComp2
