import React from 'react'

import { Helmet } from 'react-helmet'

import LoginNavbar from '../components/login-navbar'
import SignIn from '../components/sign-in'
import Footer from '../components/footer'
import './login.css'

const Login = (props) => {
  return (
    <div className="login-container">
      <Helmet>
        <title>Login - Write your own book</title>
        <meta property="og:title" content="Login - Write your own book" />
      </Helmet>
      {/* <LoginNavbar rootClassName="login-navbar-root-class-name"></LoginNavbar> */}
      <SignIn></SignIn>
      <Footer rootClassName="footer-root-class-name"></Footer>
    </div>
  )
}

export default Login
