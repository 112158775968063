import React from 'react'

import { Helmet } from 'react-helmet'

import TopNavbar from '../components/top-navbar'
import OnlineCoursesComp1 from '../components/online-courses-comp1'
import OnlineCoursesComp2 from '../components/online-courses-comp2'
import Footer from '../components/footer'
import './online-courses.css'

const OnlineCourses = (props) => {
  return (
    <div className="online-courses-container">
      <Helmet>
        <title>OnlineCourses - Write your own book</title>
        <meta
          property="og:title"
          content="OnlineCourses - Write your own book"
        />
      </Helmet>
      <div className="online-courses-navbar1">
        <TopNavbar></TopNavbar>
      </div>
      <div className="online-courses-hero2">
        <OnlineCoursesComp1></OnlineCoursesComp1>
      </div>
      <div className="online-courses-footer11">
        <Footer></Footer>
      </div>
    </div>
  )
}

export default OnlineCourses
