import React from 'react'

import PropTypes from 'prop-types'

import './book-titles-comp.css'

const BookTitlesComp = (props) => {
  return (
    <div className="book-titles-comp-container thq-section-padding">
      <div className="book-titles-comp-max-width thq-section-max-width">
        <div className="book-titles-comp-content thq-flex-column">
          <ul className="thq-flex-column">
            <li className="list-item thq-flex-column">
              <h2 className="book-titles-comp-heading1 thq-heading-2">
                {props.heading1}
              </h2>
              <p className="thq-body-small">{props.content1}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading2}</h3>
              <p className="thq-body-small">{props.content2}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading3}</h3>
              <p className="thq-body-small">{props.content3}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading4}</h3>
              <p className="thq-body-small">{props.content4}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading5}</h3>
              <p className="thq-body-small">{props.content5}</p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">{props.heading6}</h3>
              <p className="thq-body-small">{props.content6}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

BookTitlesComp.defaultProps = {
  content4:
    'Get content recommendations to enrich your book. Our platform suggests relevant topics and ideas to enhance the depth and breadth of your writing.',
  content3:
    'Ensure your book is error-free with our grammar checks. Our AI system helps you identify and correct grammar mistakes, improving the overall quality of your content.',
  content6:
    "Rest assured that your content is secure on our platform. We prioritize the security and confidentiality of authors' work, ensuring peace of mind throughout the writing process.",
  heading5: 'Google Gemini AI Integration',
  content1:
    'Our AI-powered platform assists authors in writing their books by providing automated writing capabilities. Save time and effort with our advanced technology.',
  content2:
    'Receive personalized suggestions tailored to your writing style and content. Enhance your book with insightful recommendations from our AI system.',
  heading4: 'Content Recommendations',
  content5:
    'Utilize the power of Google Gemini AI for content creation. Benefit from cutting-edge technology to create compelling and engaging book content.',
  heading1: 'Automated Writing',
  heading3: 'Grammar Checks',
  heading6: 'Secure Content Storage',
  heading2: 'Personalized Suggestions',
}

BookTitlesComp.propTypes = {
  content4: PropTypes.string,
  content3: PropTypes.string,
  content6: PropTypes.string,
  heading5: PropTypes.string,
  content1: PropTypes.string,
  content2: PropTypes.string,
  heading4: PropTypes.string,
  content5: PropTypes.string,
  heading1: PropTypes.string,
  heading3: PropTypes.string,
  heading6: PropTypes.string,
  heading2: PropTypes.string,
}

export default BookTitlesComp
