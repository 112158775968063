import React from 'react'

import PropTypes from 'prop-types'

import './proof-final-layout-comp1.css'

const ProofFinalLayoutComp1 = (props) => {
  return (
    <div className="proof-final-layout-comp1-container thq-section-padding">
      <div className="proof-final-layout-comp1-max-width thq-section-max-width">
        <div className="proof-final-layout-comp1-content">
          <h2 className="proof-final-layout-comp1-heading1 thq-heading-2">
            {props.heading1}
          </h2>
          <p className="proof-final-layout-comp1-content1 thq-body-large">
            {props.content1}
          </p>
          <div className="proof-final-layout-comp1-actions">
            <button className="thq-button-filled proof-final-layout-comp1-button">
              <span className="proof-final-layout-comp1-action1 thq-body-small">
                {props.action1}
              </span>
            </button>
            <button className="thq-button-outline proof-final-layout-comp1-button1">
              <span className="proof-final-layout-comp1-action2 thq-body-small">
                {props.action2}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

ProofFinalLayoutComp1.defaultProps = {
  action1: 'Main action',
  heading1: 'Call to action that excites the visitor to try your product',
  content1:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.',
  action2: 'Secondary action',
}

ProofFinalLayoutComp1.propTypes = {
  action1: PropTypes.string,
  heading1: PropTypes.string,
  content1: PropTypes.string,
  action2: PropTypes.string,
}

export default ProofFinalLayoutComp1
