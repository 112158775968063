import React, { useState, useEffect } from 'react';
import './book-details-comp.css'
import Box from '@mui/material/Box';
import { TextField, Button, CircularProgress, Typography, Grid  } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import dompurify from 'dompurify';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { v4 as uuidv4 } from 'uuid'; 

const firebaseConfig = {
  apiKey: process.env.REACT_APP_YOUR_API_KEY,
  authDomain: process.env.REACT_APP_YOUR_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_YOUR_PROJECT_ID,
  storageBucket: process.env.REACT_APP_YOUR_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_YOUR_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_YOUR_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const BookDetailsComp = (props) => {
  const [bookIdea, setBookIdea] = useState('');
  const [bookFormat, setBookFormat] = useState('eBook');
  const [fiction, setFiction] = useState('');
  const [bookReader, setBookReader] = useState('');
  const [bookPurpose, setBookPurpose] = useState('');
  const [bookTone, setBookTone] = useState('');
  const [bookAuthor, setBookAuthor] = useState('');
  const [writingStyle, setWritingStyle] = useState('');
  const [includeInTableOfContents, setIncludeInTableOfContents] = useState('');
  const [includeInBookTitle, setIncludeInBookTitle] = useState('');
  const [bookLength, setBookLength] = useState('');
  const [fullText, setFullText] = useState('');
  const [creativity, setCreativity] = useState('');
  const [bookTags, setBookTags] = useState('');
  const [characterDetails, setCharacterDetails] = useState('');
  const [characterName, setCharacterName] = useState('');
  const [bookTitle, setTitle] = useState('');
  const [bookSubtitle, setBookSubtitle] = useState('');
  const [tableOfContents, setTableOfContents] = useState('');
  const [listOfTitles, setListOfTitles] = useState('');
  const [firstChapter, setFirstChapter] = useState('');
  const [additionInTableOfContents, setAdditionInTableOfContents] = useState('');
  const [additionInTitleList, setAdditionInTitleList] = useState('');
  const [currentStep, setCurrentStep] = useState(1);
  const [showTableOfContents, setShowTableOfContents] = useState(false);
  const [showListOfTitles, setShowListOfTitles] = useState(false);
  const [showChapter, setShowChapter] = useState(false);
  const [encodedPdf, setEncodedPdf] = useState(null);
  const [generateTOC, setGenerateTOC] = useState('true');
  const [generateTitles, setGenerateTitles] = useState('');
  const [generateChapters, setGenerateChapters] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userid, setUserid] = useState(null);
  const [bookid, setBookid] = useState(null);
  const [chapterid, setChapterid] = useState(0);
  const [subchapterid, setSubchapterid] = useState(0);
  const [allchapters, setAllchapters] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false); // Declare showErrorMessage
  const [fetchError, setFetchError] = useState(null); 
  const [formError, setFormError] = useState(null);
  
  useEffect(() => {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);

    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, get the user ID
        setUserid(user.uid);
        sessionStorage.setItem('userid', user.uid); // Add userid to sessionStorage
        setShowErrorMessage(false); // Hide error message
        //console.log("userid from book-details:"+userid)
      } else {
        const storedUserid = sessionStorage.getItem('userid');
        //console.log("storedUserid from book-details:"+storedUserid)
        if (storedUserid) {
          setUserid(storedUserid); // Set userid from sessionStorage
          setShowErrorMessage(false); // Hide error message
        } else {
          setUserid(null);
          sessionStorage.removeItem('userid'); // Remove userid from sessionStorage
          setShowErrorMessage(true); // Hide error message
        }
      }
    });
  }, []); // Run this effect only once when the component mounts

  // Generate a random book ID when the component mounts
  useEffect(() => {
    const timestamp = Date.now(); // Get current timestamp in milliseconds
    const randomPart = Math.floor(Math.random() * 1000000); // Generate a random 6-digit number
    const bookId = `${timestamp}-${randomPart}`; // Combine timestamp and random part
    setBookid(bookId)
    //console.log('Generated book ID:', bookId);
  }, []); // Run this effect only once when the component mounts

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!fiction) {
      setFormError('Please select Fiction or Non Fiction');
      return;
    }

    // Proceed with form submission logic here...
    console.log('Form submitted:', fiction);
  };

  const getBookProof = async () => {
    setIsLoading(true);
    setFetchError(null);
    try {
      const response = await fetch(process.env.REACT_APP_GENERATE_STORY, {
        method: 'POST',
        body: JSON.stringify({
          bookidea: bookIdea,
          bookformat: bookFormat,
          fiction: fiction,
          bookreader: bookReader,
          bookpurpose: bookPurpose,
          booktone: bookTone,
          bookauthor: bookAuthor,
          writingstyle: writingStyle,
          includeintableofcontents: includeInTableOfContents,
          includeinbooktitle: includeInBookTitle,
          booklength: bookLength,
          creativity: creativity,
          characterdetails: characterDetails,
          charactername: characterName,
          tableofcontents: tableOfContents,
          listoftitles: listOfTitles,
          booktitle: bookTitle,
          userid: userid,
          bookid: bookid,
          chapterid: chapterid,
          subchapterid: subchapterid
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'Authorization': 'Bearer ' + process.env.API_KEY,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }

      const data = await response.json();
      const sanitizedFirstChapter = dompurify.sanitize(data.fullText.split('\n').join(''));
      setFullText(sanitizedFirstChapter);

      // Store the Base64 encoded PDF data
      setEncodedPdf(data.encodedPdf); // Assuming the API returns encodedPdf
    } catch (err) {
      console.error('Error fetching book proof:', err);
      setFetchError('An error occurred while fetching the book proof. Please try again later.'); 
    } finally {
      setIsLoading(false);
    }
  };

  const getBookTOCFromAPI = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_GENERATE_TOC, {
        method: 'POST',
        body: JSON.stringify({
          bookidea: bookIdea,
          bookformat: bookFormat,
          fiction: fiction,
          bookreader: bookReader,
          bookpurpose: bookPurpose,
          booktone: bookTone,
          bookauthor: bookAuthor,
          writingstyle: writingStyle,
          includeintableofcontents: includeInTableOfContents,
          includeinbooktitle: includeInBookTitle,
          booklength: bookLength,
          creativity: creativity,
          characterdetails: characterDetails,
          charactername: characterName,
          userid: userid,
          bookid: bookid
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'Authorization': 'Bearer ' + process.env.API_KEY,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }

      const data = await response.json();
      const sanitizedTableOfContents = dompurify.sanitize(data.tableOfContents.split('\n').join(''));
      setTableOfContents(sanitizedTableOfContents);

      // Store the Base64 encoded PDF data
      //setEncodedPdf(data.encodedPdf); // Assuming the API returns encodedPdf
    } catch (err) {
      setFetchError('An error occurred while fetching the book proof. Please try again later.'); 
    } finally {
      setIsLoading(false);
    }
  };

  const finishBook = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_FINISH_BOOK, {
        method: 'POST',
        body: JSON.stringify({
          bookid: bookid
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'Authorization': 'Bearer ' + process.env.API_KEY,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }

      const data = await response.json();
      const sanitizedListOfTitles = dompurify.sanitize(data.listOfTitles.split('\n').join(''));
      setListOfTitles(sanitizedListOfTitles);
      //console.log(JSON.stringify(data.listOfTitles.split('\n').join('')).replace('"', ''))
    } catch (err) {
      setFetchError('Error fetching book titles.'); 
    } finally {
      setIsLoading(false);
    }
  };

  const getBookTitlesFromAPI = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_GENERATE_TITLES, {
        method: 'POST',
        body: JSON.stringify({
          bookidea: bookIdea,
          bookformat: bookFormat,
          fiction: fiction,
          bookreader: bookReader,
          bookpurpose: bookPurpose,
          booktone: bookTone,
          bookauthor: bookAuthor,
          writingstyle: writingStyle,
          includeintableofcontents: includeInTableOfContents,
          includeinbooktitle: includeInBookTitle,
          booklength: bookLength,
          creativity: creativity,
          characterdetails: characterDetails,
          charactername: characterName,
          tableofcontents: tableOfContents,
          userid: userid,
          bookid: bookid
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'Authorization': 'Bearer ' + process.env.API_KEY,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }

      const data = await response.json();
      const sanitizedListOfTitles = dompurify.sanitize(data.listOfTitles.split('\n').join(''));
      setListOfTitles(sanitizedListOfTitles);
      //console.log(JSON.stringify(data.listOfTitles.split('\n').join('')).replace('"', ''))
    } catch (err) {
      setFetchError('Error fetching book titles.'); 
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = async () => {
    setIsLoading(true);
    //const encodedHtml = encodeURI(fullText);

    try {
      const response = await fetch(process.env.REACT_APP_GENERATE_PDF, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ html: fullText, booktitle: bookTitle }),
      });      

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', bookTitle + ".pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error('Error generating PDF:', response.status);
      }
    } catch (err) {
      setFetchError('Error downloading book.'); 
    } finally {
      setIsLoading(false);
    }
  };

  const handleTableOfContentsChange = (event) => {
    setIncludeInTableOfContents(event.target.value);
  };

  const handleTitleListChange = (event) => {
    setTitle(event.target.value);
  };

  const refreshListOfTOC = async () => {
    if (additionInTableOfContents != '')
      getBookTOCFromAPI();
      setShowListOfTitles(false);
      setShowTableOfContents(true);
  };

  return (
    <div className="book-details-comp-container thq-section-padding">
      {showErrorMessage && (
        <Typography variant="h6" color="error" sx={{ m: 2 }}>
          Please log in before you can write your books.
        </Typography>
      )}
      <div className="book-details-comp-max-width thq-section-max-width">
      {currentStep === 1 && (
         <Grid container spacing={2}> {/* Use Grid for layout */}
            <Grid item xs={12}> {/* Full width on small screens */}
              <Box
                component="form"
                sx={{
                  '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off" 
              >
                <form onSubmit={handleSubmit}>
                  <div  sx={{ m: 2 }}>
                    <TextField
                      id="book-idea"
                      label="Mandatory: Tell us more about the book you want to write. Limit input to 12000 characters (roughly 3-4 pages)"
                      value={bookIdea}
                      multiline
                      rows={15}
                      style={{ width: '100%' }}
                      onChange={(event) => setBookIdea(event.target.value)}
                      required 
                      inputProps={{ maxLength: 12000 }} // Add this to limit input to 5000 characters
                    />
                  </div>
                  <div>
                    <FormControl sx={{ m: 2 }} error={!!formError} required>
                      <FormLabel id="fiction-label">Fiction / Non Fiction (Mandatory)</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="fiction-radio-buttons-group"
                      >
                        <FormControlLabel value="fiction" control={<Radio />} label="fiction" onChange={(event) => setFiction(event.target.value)}/>
                        <FormControlLabel value="non fiction" control={<Radio />} label="non fiction" onChange={(event) => setFiction(event.target.value)}/>
                      </RadioGroup>
                      {formError && <Typography variant="caption" color="error">{formError}</Typography>} {/* Display error message */}
                    </FormControl>
                  </div>
                  <div  sx={{ m: 2 }}>
                    <TextField
                      id="book-reader"
                      label="Mandatory: Who is the reader? Their age group? Limit input to 1000 characters"
                      value={bookReader}
                      multiline
                      sx={{ m: 2 }}
                      rows={3}
                      style={{ width: '100%' }}
                      onChange={(event) => setBookReader(event.target.value)}
                      required 
                      inputProps={{ maxLength: 1000 }} // Add this to limit input to 250 characters
                    />
                  </div>
                  <div  sx={{ m: 2 }}>
                    <TextField
                      id="book-purpose"
                      label="Optional: What is the goal of writing this book? Limit input to 1000 characters"
                      value={bookPurpose}
                      multiline
                      sx={{ m: 2 }}
                      style={{ width: '100%' }}
                      rows={3}
                      inputProps={{ maxLength: 1000 }} // Add this to limit input to 500 characters
                      onChange={(event) => setBookPurpose(event.target.value)}
                    />
                  </div>
                  <div  sx={{ m: 2 }}>
                    <TextField
                      id="book-tone"
                      label="Mandatory: What is the tone of this book? Separate them by comma. For example: Informal, Humor, Persuasive, Sarcastic"
                      value={bookTone}
                      multiline
                      sx={{ m: 2 }}
                      style={{ width: '100%' }}
                      onChange={(event) => setBookTone(event.target.value)}
                      required 
                      inputProps={{ maxLength: 500 }} // Add this to limit input to 250 characters
                    />
                  </div>
                  <div  sx={{ m: 2 }}>
                    <TextField
                      id="book-author"
                      label="Mandatory: Author Name (Your Name)"
                      value={bookAuthor}
                      defaultValue={'Bookbuilder.pro'} // Set default value here
                      multiline
                      sx={{ m: 2 }}
                      style={{ width: '100%' }}
                      required 
                      onChange={(event) => setBookAuthor(event.target.value)}
                      inputProps={{ maxLength: 500 }} // Add this to limit input to 250 characters
                    />
                  </div>
                  <div  sx={{ m: 2 }}>
                    <TextField
                      id="writing-style"
                      label="Optional: Please suggest few author names whose writing style you want to pick. Separate names by comma. For example: Kurt Vonnegut, Douglas Adams, Terry Pratchett"
                      value={writingStyle}
                      multiline
                      sx={{ m: 2 }}
                      style={{ width: '100%' }}
                      onChange={(event) => setWritingStyle(event.target.value)}
                      inputProps={{ maxLength: 500 }} // Add this to limit input to 250 characters
                    />
                  </div>
                  <div  sx={{ m: 2 }}>
                    <TextField
                      id="book-size"
                      label="Optional: Tell us more about the lead character. Max 2000 characters"
                      value={characterDetails}
                      multiline
                      sx={{ m: 2 }}
                      rows={5}
                      style={{ width: '100%' }}
                      onChange={(event) => setCharacterDetails(event.target.value)}
                      inputProps={{ maxLength: 2000 }} // Add this to limit input to 250 characters
                    />
                  </div>
                  <div>
                    <FormControl sx={{ m: 2 }}>
                      <FormLabel id="creativity-label">Optional: Creativity level</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="creativity-radio-buttons-group"
                        defaultValue="High"
                      >
                        <FormControlLabel value="High" control={<Radio />} label="High" onChange={(event) => setCreativity(event.target.value)}/>
                        <FormControlLabel value="Medium" control={<Radio />} label="Medium" onChange={(event) => setCreativity(event.target.value)}/>
                        <FormControlLabel value="Low" control={<Radio />} label="Low" onChange={(event) => setCreativity(event.target.value)}/>
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div  sx={{ m: 2 }}>
                    <TextField
                      id="include-table-of-contents"
                      label="Optional: Is there anything you want to include in the table of contents? Max 2000 chcracters"
                      value={includeInTableOfContents}
                      multiline
                      sx={{ m: 2 }}
                      style={{ width: '100%' }}
                      rows={5}
                      onChange={(event) => setIncludeInTableOfContents(event.target.value)}
                      inputProps={{ maxLength: 2000 }} // Add this to limit input to 250 characters
                    />
                  </div>
                  <div  sx={{ m: 2 }}>
                    <TextField
                      id="include-titles"
                      label="Optional: Is there anything you want to include in the book title? Max 1000 chcracters"
                      value={includeInBookTitle}
                      multiline
                      sx={{ m: 2 }}
                      rows={2}
                      style={{ width: '100%' }}
                      onChange={(event) => setIncludeInBookTitle(event.target.value)}
                      inputProps={{ maxLength: 1000 }} // Add this to limit input to 250 characters
                    />
                  </div>
                  <div>
                    <FormControl sx={{ m: 2 }} error={!!formError} required>
                      <FormLabel id="book-size-format-label">Mandatory: Approximately how many pages do you want in your book?</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="book-size-radio-buttons-group"
                        defaultValue="Large"
                      >
                        <FormControlLabel value="Micro" control={<Radio />} label="Micro (3 pages, cost=$0.10 AUD)" onChange={(event) => setBookLength(event.target.value)}/>
                        <FormControlLabel value="Mini" control={<Radio />} label="Mini (10 pages, cost=$0.30 AUD)" onChange={(event) => setBookLength(event.target.value)}/>
                        <FormControlLabel value="Small" control={<Radio />} label="Small (20 pages, cost=$1 AUD)" onChange={(event) => setBookLength(event.target.value)}/>
                        <FormControlLabel value="Medium" control={<Radio />} label="Medium (40 pages, cost=$2 AUD)" onChange={(event) => setBookLength(event.target.value)}/>
                        <FormControlLabel value="Large" control={<Radio />} label="Large (75 pages, cost=$4 AUD)" onChange={(event) => setBookLength(event.target.value)}/>
                        <FormControlLabel value="XL" control={<Radio />} label="XL (150 pages, cost=$10 AUD)" onChange={(event) => setBookLength(event.target.value)}/>
                        <FormControlLabel value="XXL" control={<Radio />} label="XXL (300 pages, cost=$24 AUD)" onChange={(event) => setBookLength(event.target.value)}/>
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div>
                    <FormControl sx={{ m: 2 }} error={!!formError} required>
                      <FormLabel id="book-format-label">Mandatory: Book format</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="book-format-radio-buttons-group"
                        defaultValue="eBook"
                      >
                        <FormControlLabel value="eBook" control={<Radio />} label="eBook" onChange={(event) => setBookFormat(event.target.value)}/>
                        <FormControlLabel value="Printbook" disabled control={<Radio />} label="Printbook" onChange={(event) => setBookFormat(event.target.value)}/>
                        <FormControlLabel value="Audiobook" disabled control={<Radio />} label="Audiobook" onChange={(event) => setBookFormat(event.target.value)}/>
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="book-details-comp-actions"  sx={{ m: 2 }}>
                    <Button className="thq-button-filled book-details-comp-button"  sx={{ m: 5 }}
                      type="button" variant="contained" disabled={showErrorMessage} 
                      onClick={() => {
                        if (!bookIdea && !fiction && !bookReader && !bookTone && !bookAuthor && !bookLength) {
                          alert('Please fill all mandatory fields'); // Or display an error message in a better way
                          return; // Stop execution if no title is selected
                        }

                        getBookTOCFromAPI();
                        setShowTableOfContents(true);
                        setCurrentStep(2);
                      }}>Get table of contents</Button> 
                  </div>
                </form>
              </Box>
            </Grid> 
          </Grid>
        )}
        {showTableOfContents && (
        <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off" 
          >
          {!tableOfContents ? ( // Show loading indicator if isLoadingTOC is true
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                <CircularProgress />
                <span style={{ marginLeft: '16px' }}>Please wait as table of content is being loaded...</span>
              </div>
            ) : (
              <div  sx={{ m: 2 }}>
                <div dangerouslySetInnerHTML={{ __html: tableOfContents }} />
              </div>
          )}
          <div  sx={{ m: 2 }}>
              <div  sx={{ m: 2 }}>
                  <TextField
                    id="display-table-of-contents"
                    label="Optional: Is there anything you want to change in the table of contents? Max 1000 characters"
                    value={additionInTableOfContents}
                    multiline
                    sx={{ m: 15 }}
                    rows={5}
                    style={{ width: '100%' }}
                    onChange={handleTableOfContentsChange}
                    inputProps={{ maxLength: 1000 }} // Add this to limit input to 250 characters
                  >
                    {dompurify.sanitize(tableOfContents)}
                  </TextField>
              </div>
          </div>
          <div className="book-details-comp-actions"  sx={{ m: 2 }}>
          <Button
            className="thq-button-filled book-details-comp-button"
            sx={{ m: 5 }}
            variant="contained"
            style={{ width: '30%' }}
            disabled={!tableOfContents || showErrorMessage}
            onClick={() => {
              getBookTitlesFromAPI();
              setShowListOfTitles(true);
              setShowTableOfContents(false);
            }}
          >
            All good. Get book list of titles
          </Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {/* <Button
            className="thq-button-filled book-details-comp-button"
            sx={{ m: 5 }}
            variant="contained"
            onClick={refreshListOfTOC}
            disabled={!tableOfContents || showErrorMessage}
          >
            Refresh table of contents based on feedback
          </Button> */}
          </div>
        </Box>
        )}
        {showListOfTitles && (
          <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off" 
            >
          <div  sx={{ m: 2 }}>
              <div  sx={{ m: 2 }}>
                {!listOfTitles ? ( // Show loading indicator if isLoadingTOC is true
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                      <CircularProgress />
                      <span style={{ marginLeft: '16px' }}>Please wait as list of titles is being loaded...</span>
                    </div>
                  ) : (
                    <div  sx={{ m: 2 }}>
                      <div dangerouslySetInnerHTML={{ __html: listOfTitles }} />
                    </div>
                )}
                  <TextField
                    id="display-title-list"
                    label="Mandatory: Please select the title and copy paste the title here"
                    value={bookTitle}
                    multiline
                    sx={{ m: 15 }}
                    style={{ width: '100%' }}
                    onChange={handleTitleListChange}
                    required
                    inputProps={{ maxLength: 500 }} // Add this to limit input to 250 characters
                  >
                  </TextField>
              </div>
          </div>
          <div className="book-details-comp-actions"  sx={{ m: 2 }}>
            <Button
              className="thq-button-filled book-details-comp-button"
              sx={{ m: 5 }}
              variant="contained"
              disabled={!listOfTitles || showErrorMessage}
              style={{ width: '30%' }}
              onClick={() => {
                if (!bookTitle) {
                  alert('Please select a book title!'); // Or display an error message in a better way
                  return; // Stop execution if no title is selected
                }

                getBookProof();
                setShowListOfTitles(false);
                setShowTableOfContents(false);
                setShowChapter(true);
              }}
            >
              Show chapters (initially max 10 chapters. Cost $2 AUD)
            </Button>
          </div>
          </Box>
        )}
        {showChapter && (
          <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <div sx={{ m: 2 }}>
            <div sx={{ m: 2 }}>
              {!fullText ? (
                // Show loading indicator if isLoadingTOC is true
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100px',
                  }}
                >
                  {isLoading ? (
                    <CircularProgress />
                  ) : (
                    fetchError && (
                      <Typography variant="body1" color="error">
                        {fetchError}
                      </Typography>
                    )
                  )}
                  {isLoading && (
                    <span style={{ marginLeft: '16px' }}>
                      Your book is being generated. This may take 5 to 30 minutes depending of the size of the book. Please avoid refreshing the page. Maximum of first 10 chapters will be available initially. You can download the book and generate the next 10 chapters from the "Your Books" section.
                    </span>
                  )}
                </div>
              ) : (
                <> {/* Wrap the Button and div in a JSX fragment */}
                  <div className="book-details-comp-actions" sx={{ m: 2 }}>
                    <Button
                      className="thq-button-filled book-details-comp-button"
                      sx={{ m: 5 }}
                      variant="contained"
                      style={{ width: '30%' }}
                      onClick={handleDownload} // Call downloadPdf on click
                      disabled={isLoading || !fullText  || showErrorMessage}
                    >
                      {isLoading ? 'Generating PDF...' : 'Download PDF'}
                    </Button>
                  </div>
                  <div sx={{ m: 2 }}>
                    <div dangerouslySetInnerHTML={{ __html: fullText }} />
                  </div>
                </>
              )}
            </div>
          </div>
        </Box>
        )}
      </div>
    </div>
  )
}

export default BookDetailsComp
