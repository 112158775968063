import React from 'react'

import { Helmet } from 'react-helmet'

import TopNavbar from '../components/top-navbar'
import BookTitlesComp1 from '../components/book-titles-comp1'
import BookTitlesComp from '../components/book-titles-comp'
import Footer from '../components/footer'
import './book-titles.css'

const BookTitles = (props) => {
  return (
    <div className="book-titles-container">
      <Helmet>
        <title>BookTitles - Write your own book</title>
        <meta property="og:title" content="BookTitles - Write your own book" />
      </Helmet>
      <div className="book-titles-navbar1">
        <TopNavbar></TopNavbar>
      </div>
      <div className="book-titles-cta2">
        <BookTitlesComp1></BookTitlesComp1>
      </div>
      <div className="book-titles-contentlist3">
        <BookTitlesComp></BookTitlesComp>
      </div>
      <div className="book-titles-footer11">
        <Footer></Footer>
      </div>
    </div>
  )
}

export default BookTitles
