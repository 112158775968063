import PropTypes from 'prop-types'
import './your-books-comp1.css'
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { TextField, Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Link } from '@mui/material';
import dompurify from 'dompurify';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { v4 as uuidv4 } from 'uuid'; 
import { Alert } from '@mui/material'; 


const firebaseConfig = {
  apiKey: process.env.REACT_APP_YOUR_API_KEY,
  authDomain: process.env.REACT_APP_YOUR_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_YOUR_PROJECT_ID,
  storageBucket: process.env.REACT_APP_YOUR_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_YOUR_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_YOUR_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const YourBooksComp1 = (props) => {
  const [bookid, setBookid] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // State for loading indicator
  const [bookTitle, setBookTitle] = useState('');
  const [fullText, setFullText] = useState('');
  const [error, setError] = useState(null); // Add this line
  const [userid, setUserid] = useState(null);
  const [books, setBooks] = useState([]); // State to store fetched books
  const [showErrorMessage, setShowErrorMessage] = useState(false); // Declare showErrorMessage

  // Add a new state variable to track table loading
  const [isTableLoading, setIsTableLoading] = useState(true); 
  const [isFinishingBook, setIsFinishingBook] = useState(false);
  const [finishingBookId, setFinishingBookId] = useState(null);
  const [message, setMessage] = useState(null);
  // Add new state to track download status
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadingBookId, setDownloadingBookId] = useState(null);
  const [downloadMessage, setDownloadMessage] = useState(null);

  const [showAdditionalContextInput, setShowAdditionalContextInput] = useState(false);
  const [additionalContext, setAdditionalContext] = useState('');

  useEffect(() => {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, get the user ID
        setUserid(user.uid);
        sessionStorage.setItem('userid', user.uid); // Add userid to sessionStorage
        //console.log("userid from yourbook:"+userid)
        fetchBooks(); // Fetch books after userid is set
        setShowErrorMessage(false); // Hide error message
      } else {
        // User is signed out
        // Check if userid exists in sessionStorage
        const storedUserid = sessionStorage.getItem('userid');
        //console.log("storedUserid from yourbook:"+storedUserid)
        if (storedUserid) {
          setUserid(storedUserid); // Set userid from sessionStorage
          fetchBooks(); // Fetch books using the stored userid
          setShowErrorMessage(false); // Hide error message
        } else {
          setUserid(null);
          sessionStorage.removeItem('userid'); // Remove userid from sessionStorage
          setBooks([]); // Clear books when user signs out
          setShowErrorMessage(true); // Hide error message
        }
      }
    });
  }, []); // Run this effect only once when the component mounts

  // Fetch books from your API
  const fetchBooks = async () => {
    setIsLoading(true);
    setError(null);

    const storedUserid = sessionStorage.getItem('userid');
    if (storedUserid) {
      setUserid(storedUserid); // Set userid from sessionStorage
    } 

    try {
      const response = await fetch(process.env.REACT_APP_USER_BOOKS, { // Use REACT_APP_USER_BOOKS
        method: 'POST',
        body: JSON.stringify({
          userid: storedUserid
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'Authorization': 'Bearer ' + process.env.API_KEY,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }

      const data = await response.json();
      setBooks(data.books); // Update the books state with fetched data
      setIsTableLoading(false);
    } catch (err) {
      //console.error('Error fetching books:', err);
      setIsTableLoading(false);
      setError('An error occurred while fetching books.');
    } finally {
      setIsLoading(false);
    }
  };

  const finishBook = async (bookid) => {
    if (showAdditionalContextInput) {
      setIsLoading(true); // Set loading to true before API call
      setIsFinishingBook(true); // Disable the button
      setFinishingBookId(bookid); // Store the book ID being finished
      setMessage(null);
      try {
        const response = await fetch(process.env.REACT_APP_FINISH_BOOK, {
          method: 'POST',
          body: JSON.stringify({
            bookid: bookid,
            additionalContext: additionalContext
          }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + process.env.API_KEY,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }

        const data = await response.json();
        //console.log(data.error)
        if (data.error) {
          setIsLoading(false); // Set loading to false in case of error
          setIsFinishingBook(false);
          setFinishingBookId(null);
          //alert(data.error)
          setMessage(data.error);
        } else {
          setIsLoading(false); // Set loading to false in case of error
          setIsFinishingBook(false);
          setFinishingBookId(null);
          //setShowAdditionalContextInput(true);
          showErrorMessage || isFinishingBook
          setMessage('The next 10 chapters are ready! Download to review your book progress.');
        }

        setIsLoading(false); // Set loading to false after API call
      } catch (err) {
        //console.error(err);
        console.error('Error finishing book'); // Log the error
        setIsLoading(false); // Set loading to false in case of error
        setIsFinishingBook(false);
        setFinishingBookId(null);
        //alert('The next 10 chapters are ready! Download to review your book progress.'); 
        setMessage('Error finishing book');
      }
    } else {
      // If the input is not shown, show it to get additional context
      setFinishingBookId(bookid); // Store the book ID being finished
      setShowAdditionalContextInput(true);
    }
  };

  const getBookProof = async (bookId) => {
    setIsLoading(true);
    setError(null);
    //console.log("bookId from getBookProof:"+bookId)

    try {
      const response = await fetch(process.env.REACT_APP_DOWNLOAD_BOOK, {
        method: 'POST',
        body: JSON.stringify({
          bookid: bookId
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'Authorization': 'Bearer ' + process.env.API_KEY,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }

      const data = await response.json();

      // Sanitize and set state directly in getBookProof
      const sanitizedFirstChapter = dompurify.sanitize(data.fullText.split('\n').join(''));
      setFullText(sanitizedFirstChapter); // Set the fullText state here
      setBookTitle(data.booktitle); 

      return data;

    } catch (err) {
      console.error('Error fetching book proof');
      setError('An error occurred while fetching the book proof.'); 
    } finally {
        setIsLoading(false);
    }
  };

  
  const handleBook = async (bookid) => {
    window.location.href = '/book/'+bookid;
  }

  const handlePdfDownload = async (bookid) => {
    setMessage('Please wait, your book is being downloaded... It may take upto 1 minutes for book to download. Please make sure your browser allows file download');
    setIsLoading(true);
    setDownloadingBookId(bookid); 
    setError(null);
    //console.log("bookId from handlePdfDownload:"+bookid)
    
    try {
      const bookData = await getBookProof(bookid); 
      // Simulate a delay (replace with your actual logic)
      await new Promise(resolve => setTimeout(resolve, 5000)); // Wait for 1 second

      const response = await fetch(process.env.REACT_APP_GENERATE_PDF, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
            html: bookData.fullText,  
            booktitle: bookData.booktitle  
        }),
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', bookData.booktitle + ".pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setMessage('Your book has been downloaded and can be found in the download folder of your computer'); // Set message
      } else {
        console.error('Error generating PDF:', response.status);
        setError('An error occurred while generating the PDF.');
      }
    } catch (err) {
        console.error('Error generating PDF');
        setError('An error occurred while generating the PDF.');
    } finally {
        setIsLoading(false);
        setIsDownloading(false);
        setDownloadingBookId(null); 
    }
  };

  const handleWordDownload = async (bookid) => {
    setMessage('Please wait, your book is being downloaded... It may take upto 1 minutes for book to download. Please make sure your browser allows file download'); // Set messagesetMessage
    setIsLoading(true);
    setDownloadingBookId(bookid);
    setError(null);
    //console.log("bookId from handleWordDownload:"+bookid)

    try {
      const bookData = await getBookProof(bookid); 
      // Simulate a delay (replace with your actual logic)
      await new Promise(resolve => setTimeout(resolve, 5000)); // Wait for 1 second

      const response = await fetch(process.env.REACT_APP_GENERATE_WORD, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
            html: bookData.fullText,  
            booktitle: bookData.booktitle  
        }),
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', bookData.booktitle + ".docx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setMessage('Your book has been downloaded and can be found in the download folder of your computer'); // Set message
      } else {
        console.error('Error generating word document:', response.status);
        setError('An error occurred while generating the PDF.');
      }
    } catch (err) {
        console.error('Error generating word document');
        setError('An error occurred while generating the word document.');
    } finally {
        setIsLoading(false);
        setIsDownloading(false);
        setDownloadingBookId(null);
    }
  };

  return (
    <div className="your-books-comp1-container thq-section-padding">
      {showErrorMessage && (
        <Typography variant="h6" color="error" sx={{ m: 2 }}>
          Please log in to view your books.
        </Typography>
      )}

      {/* Display finishing message */}
      {isFinishingBook && (
        <Alert severity="info" sx={{ m: 2 }}>
          Finishing book with ID: {finishingBookId}. Please wait.
        </Alert>
      )}

      {message && (
        <Alert severity="info" sx={{ m: 2 }}>
          {message}
        </Alert>
      )}

      {/* Conditionally render the table or loading indicator */}
      {isTableLoading ? ( 
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
          <CircularProgress />
          <span style={{ marginLeft: '16px' }}>Please wait as the table is being loaded...</span>
        </div>
      ) : (
        <TableContainer sx={{ m: 2, border: '1px solid #ccc', borderRadius: '8px', padding: '16px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Book ID</TableCell>
                <TableCell>Book Title</TableCell>
                <TableCell>Download PDF</TableCell>
                <TableCell>Download Word</TableCell>
                <TableCell>Finish Book</TableCell>
                {/* Add more columns as needed */}
              </TableRow>
            </TableHead>
            <TableBody>
              {books.map((book, index) => (
                <TableRow key={book.bookid} sx={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white' }}> 
                  <TableCell>
                    <Link 
                    href="#" 
                    onClick={() => handleBook(book.bookid)}> {/* Link to the book page */}
                      {book.bookid}
                    </Link>
                  </TableCell>
                  <TableCell>{book.booktitle}</TableCell>
                  <TableCell>
                    {isDownloading && downloadingBookId === book.bookid && (
                      <Typography variant="body2">{downloadMessage}</Typography> 
                    )}
                    {!isDownloading && (
                      <Link 
                        href="#" 
                        onClick={() => handlePdfDownload(book.bookid)} 
                        disabled={showErrorMessage || isDownloading} 
                      >
                        Download PDF
                      </Link>
                    )}
                  </TableCell>
                  <TableCell>
                    {isDownloading && downloadingBookId === book.bookid && (
                      <Typography variant="body2">{downloadMessage}</Typography>
                    )}
                    {!isDownloading && (
                      <Link 
                        href="#" 
                        onClick={() => handleWordDownload(book.bookid)} 
                        disabled={showErrorMessage || isDownloading} 
                      >
                        Download Word
                      </Link>
                    )}
                  </TableCell>
                  <TableCell>
                    {showAdditionalContextInput && finishingBookId === book.bookid && (
                      <TextField
                        label="Additional Context (Optional)  max 1000 characters"
                        multiline
                        rows={10}
                        fullWidth 
                        value={additionalContext}
                        disabled={showErrorMessage || isFinishingBook}
                        onChange={(e) => setAdditionalContext(e.target.value)}
                        inputProps={{ maxLength: 1000 }} // Add this to limit input to 5000 characters
                      />
                    )}
                    <Button
                      className="thq-button-filled book-details-comp-button"
                      sx={{ m: 5 }}
                      variant="contained"
                      onClick={() => finishBook(book.bookid)}
                      disabled={showErrorMessage || isFinishingBook}
                    >
                      {showAdditionalContextInput && finishingBookId === book.bookid ? "Confirm and Finish" : "Finish Next 10 Chapters"}
                    </Button>
                  </TableCell>
                  {/* Add more cells for other book properties */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  )
}

export default YourBooksComp1
