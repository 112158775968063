import React from 'react'

import { Helmet } from 'react-helmet'

import TopNavbar from '../components/top-navbar'
import BookDetailsComp1 from '../components/book-details-comp1'
import BookDetailsComp from '../components/book-details-comp'
import Footer from '../components/footer'
import './book-details.css'

const BookDetails = (props) => {
  return (
    <div className="book-details-container">
      <Helmet>
        <title>BookDetails - Write your own book</title>
        <meta property="og:title" content="BookDetails - Write your own book" />
      </Helmet>
      <div className="book-details-navbar1">
        <TopNavbar></TopNavbar>
      </div>
      <div className="book-details-contentlist3">
        <BookDetailsComp></BookDetailsComp>
      </div>
      <div className="book-details-footer11">
        <Footer></Footer>
      </div>
    </div>
  )
}

export default BookDetails
