import React from 'react'

import { Helmet } from 'react-helmet'

import TopNavbar from '../components/top-navbar'
import Pricing6 from '../components/pricing6'
import PricingFeatures from '../components/pricing-features'
import FAQ11 from '../components/faq11'
import Footer from '../components/footer'
import './pricing.css'

const Pricing = (props) => {
  return (
    <div className="pricing-container">
      <Helmet>
        <title>Pricing - Write your own book</title>
        <meta property="og:title" content="Pricing - Write your own book" />
      </Helmet>
      <div className="pricing-navbar1">
        <TopNavbar></TopNavbar>
      </div>
      <div className="pricing-pricing2">
        <Pricing6></Pricing6>
      </div>
      <div className="pricing-logos3"></div>
      <div className="pricing-features4">
        <PricingFeatures></PricingFeatures>
      </div>
      <div className="pricing-faq5">
        <FAQ11></FAQ11>
      </div>
      <div className="pricing-footer11">
        <Footer></Footer>
      </div>
    </div>
  )
}

export default Pricing
