import React from 'react'

import PropTypes from 'prop-types'

import './book-titles-comp1.css'

const BookTitlesComp1 = (props) => {
  return (
    <div className="book-titles-comp1-container thq-section-padding">
      <div className="book-titles-comp1-max-width thq-section-max-width">
        <div className="book-titles-comp1-content">
          <h2 className="book-titles-comp1-heading1 thq-heading-2">
            {props.heading1}
          </h2>
          <p className="book-titles-comp1-content1 thq-body-large">
            {props.content1}
          </p>
          <div className="book-titles-comp1-actions">
            <button className="thq-button-filled book-titles-comp1-button">
              <span className="book-titles-comp1-action1 thq-body-small">
                {props.action1}
              </span>
            </button>
            <button className="thq-button-outline book-titles-comp1-button1">
              <span className="book-titles-comp1-action2 thq-body-small">
                {props.action2}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

BookTitlesComp1.defaultProps = {
  action1: 'Start Writing Your Book Now',
  heading1: 'Unlock Your Writing Potential',
  content1:
    'Experience the power of AI-assisted book writing and create your masterpiece with ease.',
  action2: 'Sign Up for Free',
}

BookTitlesComp1.propTypes = {
  action1: PropTypes.string,
  heading1: PropTypes.string,
  content1: PropTypes.string,
  action2: PropTypes.string,
}

export default BookTitlesComp1
