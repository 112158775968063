import React from 'react'

import { Helmet } from 'react-helmet'

import TopNavbar from '../components/top-navbar'
import UsageLogComp1 from '../components/usage-log-comp1'
import UsageLogComp2 from '../components/usage-log-comp2'
import Footer from '../components/footer'
import './usage-log.css'

const UsageLog = (props) => {
  return (
    <div className="usage-log-container">
      <Helmet>
        <title>UsageLog - Write your own book</title>
        <meta property="og:title" content="UsageLog - Write your own book" />
      </Helmet>
      <div className="usage-log-navbar1">
        <TopNavbar></TopNavbar>
      </div>
      <div className="usage-log-cta2">
        <UsageLogComp1></UsageLogComp1>
      </div>
      <div className="usage-log-contentlist3">
        <UsageLogComp2></UsageLogComp2>
      </div>
      <div className="usage-log-footer11">
        <Footer></Footer>
      </div>
    </div>
  )
}

export default UsageLog
