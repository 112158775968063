import React from 'react'

import PropTypes from 'prop-types'

import './terms1.css'

const Terms1 = (props) => {
  return (
    <div
      className={`terms1-container thq-section-padding ${props.rootClassName} `}
    >
      <div className="terms1-max-width thq-section-max-width">
        <div className="terms1-content thq-flex-column">
        <h1>Legal Disclaimer for bookbuilder.pro by A.C.N. 679 972 199 PTY LTD</h1>

<p><strong>PLEASE READ THIS DISCLAIMER CAREFULLY BEFORE USING THE BOOKBUILDER.PRO SERVICE OFFERED BY A.C.N. 679 972 199 PTY LTD ("bookbuilder.pro", "WE", "US", OR "OUR").</strong></p>

<p><strong>Last Update: September 9, 2024</strong></p>

<h2>1. No Responsibility for Generated Content</h2>

<p>A.C.N. 679 972 199 PTY LTD is not responsible for any content generated by the bookbuilder.pro service. The content produced by bookbuilder.pro is a result of artificial intelligence algorithms and does not reflect the views, opinions, or policies of A.C.N. 679 972 199 PTY LTD.</p>

<h2>2. Plagiarism and Copyright Infringements</h2>

<p>The user acknowledges that it is their sole responsibility to ensure that content generated by bookbuilder.pro does not infringe upon the rights of third parties, including but not limited to copyright and plagiarism. While bookbuilder.pro aims to produce unique content, we cannot guarantee that the generated material is free from similarities to existing works.</p>

<h2>3. Rights to AI-Generated Content</h2>

<p>The user retains rights to the book or content generated through bookbuilder.pro to the extent that one can claim rights over AI-generated content under prevailing laws and regulations, and provided the characters used to create the book have been paid for by the user. Demands for refunds, if granted, result in the publishing rights of the books created using the refunded characters reverting to bookbuilder.pro (or A.C.N. 679 972 199 PTY LTD.). A.C.N. 679 972 199 PTY LTD makes no representations or warranties about the exclusivity, originality, or proprietary nature of the generated content.</p>

<h2>4. User Responsibility</h2>

<p>The user assumes full responsibility for any repercussions resulting from publishing or distributing AI-generated content. If a retailer or platform prohibits or restricts the submission of AI-generated content, it is the user's responsibility to be aware of such policies and to comply accordingly.</p>

<h2>5. No Warranty</h2>

<p>The bookbuilder.pro service is provided on an "as-is" and "as-available" basis. A.C.N. 679 972 199 PTY LTD does not guarantee that the service will meet your specific requirements or that the generated content will be error-free, reliable, or of a particular quality.</p>

<h2>6. Limitation of Liability</h2>

<p>Under no circumstances, including but not limited to negligence, shall A.C.N. 679 972 199 PTY LTD be liable for any direct, indirect, incidental, special, or consequential damages that result from the use of, or the inability to use, the bookbuilder.pro service.</p>   

<h2>7. Indemnification</h2>

<p>The user agrees to indemnify and hold harmless A.C.N. 679 972 199 PTY LTD, its officers, directors, employees, and agents from and against any claims, actions, demands, liabilities, costs, or expenses, including legal and accounting fees, arising from the use of the bookbuilder.pro service or breach of this disclaimer.</p>   

<h2>8. Governing Law</h2>

<p>This disclaimer shall be governed by and construed in accordance with the laws of the State of Massachusetts, USA, without regard to its conflict of laws rules.</p>   

<h2>9. Changes to the Disclaimer</h2>

<p>A.C.N. 679 972 199 PTY LTD reserves the right to modify this disclaimer at any time. Users are encouraged to frequently check this disclaimer for any changes. Your continued use of the bookbuilder.pro service after the posting of changes constitutes your binding acceptance of such changes.</p>

<h2>10. Entire Agreement</h2>

<p>This disclaimer constitutes the entire agreement between the user and A.C.N. 679 972 199 PTY LTD concerning the use of the bookbuilder.pro service and supersedes any prior agreements, understandings, or representations.</p>

<h2>11. Ghostwriting Service</h2>

<p>This is a service where you provide the idea (and some suggestions or guidance if you wish), and we take care of everything. This means you trust our judgement to create the table of contents, the content of the book, design the cover, proof the book, and choose the best narrator (if doing narration). If we are doing the distribution, you will have the opportunity to read the book prior to distribution. We are happy to fix any errors if we missed any.</p>

<h2>12. Cancellation Policy</h2>

<p>We are committed to making cancellation of the service no more difficult than signing up for the service. To that extent, we require users cancelling service to use the same process they used to upgrade service. That helps us improve our service and also authenticates the cancellation request and automatically calculates and processes any refunds. Please understand these terms and conditions before signing up for service:</p>

<ul>
<li>Yearly service cannot be refunded after 30 days.</li>
<li>Within the 30 days, we can refund any funds less that which have been used. The amount used will be priced at non-member prices, currently increments of $1 per 5000 characters. To be eligible for the refund, you must cancel through the client portal using the "Downgrade" button. Cancelling through PayPal or a credit card company directly will not calculate any refunds, and doing this may also prematurely terminate your plan.</li>
<li>Allow 72 hours for cancellations requests to take effect.</li>
</ul>

<h2>13. Refund Policy</h2>

<ul>
<li>Characters purchased can be refunded within 30 days if not used. Any characters used cannot be refunded for any reason. If there is a technical issue having to do with the bookbuilder.pro software, not by the limitations of AI, we will restore the characters to the account that were affected by the issue. After 30 days, no refunds are given.</li>
<li>Ghostwriting / Idea to Market refunds. As per our guarantee, if you are not happy with the book after we send you the proof, and you do not approve the proof, we can take ownership of the book and we will publish the book under our name. The copyright for the book reverts to us on the rejection of the proof and the request for a refund. This applies to only one book. If you are not sure if you will like the books, do not order more than one at a time.</li>
</ul>

<h2>14. Free Distribution Conditions</h2>

<p>For some of our top plans, we offer free distribution of the ebook, audiobook, and print book. This offer only applies to books created while subscribed to the plan and only to the number of books per month that the plan allows. For example, free distribution would not apply to a subscriber temporarily upgrading in order to distribute their backlog of books created under a lower plan. Users on a plan that allows 10 books per month (King) get 10 free distributions for that monthly cycle, even if they create 20 small books or 20 large books where they have paid extra for non-expiring characters. Like all distributions, these books must meet the criteria of being created on bookbuilder.pro, covers created by our partner vendor, and audiobook created through AuthorVoices.ai (if audiobook).</p>

<h2>15. Account Restrictions</h2>

<p>Each individual is permitted to create and maintain only one account on our service. The creation, use, or maintenance of multiple accounts, including but not limited to those utilizing different email addresses or other identifying information, is strictly prohibited. Violation of this policy may result in the suspension or termination of all associated accounts at the sole discretion of bookbuilder.pro. Each user must ensure that all account details remain accurate, up-to-date, and unique to their identity. Sharing or transferring accounts between individuals is also prohibited without express permission.</p>

<p><strong>By using the bookbuilder.pro service, you acknowledge that you have read, understood, and agree to be bound by the terms and conditions of this disclaimer.</strong></p>
        </div>
      </div>
    </div>
  )
}

Terms1.defaultProps = {
  rootClassName: '',
  heading6: 'Download in Various Formats',
  heading3: 'Grammar Checks and Content Recommendations',
  content5:
    "We prioritize the security and confidentiality of authors' content, ensuring that their work is protected at all times.",
  content6:
    'Authors can easily download their completed books in various formats, making it convenient for publishing or sharing.',
  heading4: 'Fully Automated Writing Option',
  content4:
    'Authors can opt for fully automated writing using cutting-edge Generative AI technology for a seamless writing experience.',
  content1:
    'Our platform utilizes advanced AI algorithms to assist authors in writing their books efficiently and effectively.',
  content2:
    'Authors receive personalized writing suggestions tailored to their writing style and content, enhancing the overall quality of their books.',
  content3:
    'The AI-powered system provides grammar checks and content recommendations to ensure the text is error-free and engaging.',
  heading1: 'AI-Powered Book Writing Platform',
  heading5: 'Security and Confidentiality',
  heading2: 'Personalized Writing Suggestions',
}

Terms1.propTypes = {
  rootClassName: PropTypes.string,
  heading6: PropTypes.string,
  heading3: PropTypes.string,
  content5: PropTypes.string,
  content6: PropTypes.string,
  heading4: PropTypes.string,
  content4: PropTypes.string,
  content1: PropTypes.string,
  content2: PropTypes.string,
  content3: PropTypes.string,
  heading1: PropTypes.string,
  heading5: PropTypes.string,
  heading2: PropTypes.string,
}

export default Terms1
