import React from 'react'

import PropTypes from 'prop-types'

import './pricing-features.css'

const PricingFeatures = (props) => {
  return (
    <div className="pricing-features-layout251 thq-section-padding">
      <div className="pricing-features-max-width thq-section-max-width">
        <div className="thq-flex-row pricing-features-section-title">
          <div className="pricing-features-column thq-flex-column">
            <span className="thq-body-small">{props.slogan}</span>
            <h2 className="thq-heading-2 pricing-features-text1">
              {props.sectionTitle}
            </h2>
          </div>
          <span className="thq-body-small">{props.sectionDescription}</span>
        </div>
        <div className="pricing-features-content">
          <div className="pricing-features-row thq-flex-row">
            <div className="pricing-features-feature1 thq-flex-column">
              <img
                alt={props.feature1ImageAlt}
                src={props.feature1ImageSrc}
                className="thq-img-ratio-4-3 pricing-features-feature1-image"
              />
              <div className="pricing-features-content1 thq-flex-column">
                <h3 className="thq-heading-3">{props.feature1Title}</h3>
                <span className="thq-body-small">
                  {props.feature1Description}
                </span>
              </div>
            </div>
            <div className="pricing-features-feature2 thq-flex-column">
              <img
                alt={props.feature2ImageAlt}
                src={props.feature2ImageSrc}
                className="thq-img-ratio-4-3 pricing-features-feature2-image"
              />
              <div className="pricing-features-content2 thq-flex-column">
                <h3 className="thq-heading-3">{props.feature2Title}</h3>
                <span className="thq-body-small">
                  {props.feature2Description}
                </span>
              </div>
            </div>
            <div className="pricing-features-feature3 thq-flex-column">
              <img
                alt={props.feature3ImageAlt}
                src={props.feature3ImageSrc}
                className="thq-img-ratio-4-3 pricing-features-feature3-image"
              />
              <div className="pricing-features-content3 thq-flex-column">
                <h3 className="thq-heading-3">{props.feature3Title}</h3>
                <span className="thq-body-small">
                  {props.feature3Description}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="pricing-features-actions">
          <button className="thq-button-filled pricing-features-button">
            <span className="thq-body-small">{props.mainAction}</span>
          </button>
          <button className="thq-button-outline pricing-features-button1">
            <span className="thq-body-small">{props.secondaryAction}</span>
          </button>
        </div>
      </div>
    </div>
  )
}

PricingFeatures.defaultProps = {
  feature3Description:
    'Prioritize content security and confidentiality with our platform.',
  feature1Description:
    'Receive instant grammar checks and corrections as you write.',
  feature1ImageSrc:
    'https://images.unsplash.com/photo-1515325595179-59cd5262ca53?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxNjc3NTU3N3w&ixlib=rb-4.0.3&q=80&w=1080',
  feature3Title: 'Content Security',
  secondaryAction: 'Content Recommendations',
  feature3ImageSrc:
    'https://images.unsplash.com/photo-1627067324578-61af969907fa?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxNjc3NTU3N3w&ixlib=rb-4.0.3&q=80&w=1080',
  feature2ImageSrc:
    'https://images.unsplash.com/photo-1613796434552-319eb4997874?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxNjc3NTU3N3w&ixlib=rb-4.0.3&q=80&w=1080',
  feature3ImageAlt: 'Content Security Icon',
  slogan: 'Craft compelling books with ease',
  mainAction: 'AI Writing Assistance',
  feature2Description:
    'Access a dynamic table of contents that organizes your book seamlessly.',
  feature1Title: 'Grammar Checks',
  feature1ImageAlt: 'Grammar Checks Icon',
  feature2Title: 'Table of Contents',
  feature2ImageAlt: 'Table of Contents Icon',
  sectionTitle: 'Features',
  sectionDescription:
    'Explore the powerful features of our AI-powered book writing platform.',
}

PricingFeatures.propTypes = {
  feature3Description: PropTypes.string,
  feature1Description: PropTypes.string,
  feature1ImageSrc: PropTypes.string,
  feature3Title: PropTypes.string,
  secondaryAction: PropTypes.string,
  feature3ImageSrc: PropTypes.string,
  feature2ImageSrc: PropTypes.string,
  feature3ImageAlt: PropTypes.string,
  slogan: PropTypes.string,
  mainAction: PropTypes.string,
  feature2Description: PropTypes.string,
  feature1Title: PropTypes.string,
  feature1ImageAlt: PropTypes.string,
  feature2Title: PropTypes.string,
  feature2ImageAlt: PropTypes.string,
  sectionTitle: PropTypes.string,
  sectionDescription: PropTypes.string,
}

export default PricingFeatures
