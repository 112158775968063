import React from 'react'

import PropTypes from 'prop-types'

import './ready-to-write-book-comp.css'

const ReadyToWriteBookComp = (props) => {
  return (
    <div className="ready-to-write-book-comp-container thq-section-padding">
      <div className="ready-to-write-book-comp-max-width thq-section-max-width">
        <div className="ready-to-write-book-comp-content">
          <h2 className="thq-heading-2">{props.heading1}</h2>
          <p className="thq-body-large">{props.content1}</p>
          <div className="ready-to-write-book-comp-container1">
            <div className="ready-to-write-book-comp-row thq-flex-column">
              <div className="ready-to-write-book-comp-container2">
                <button type="button" className="thq-button-filled">
                  {props.action1}
                </button>
              </div>
            </div>
            <span className="thq-body-small">{props.content2}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

ReadyToWriteBookComp.defaultProps = {
  action1: 'Write Your Book',
  content1:
    "Sign up now and start writing your book with the help of our AI-powered platform. Say goodbye to writer's block and hello to a seamless writing experience!",
  heading1: 'Ready to Write Your Book with AI Assistance?',
  content2:
    "By clicking Sign Up you're confirming that you agree with our Terms and Conditions",
}

ReadyToWriteBookComp.propTypes = {
  action1: PropTypes.string,
  content1: PropTypes.string,
  heading1: PropTypes.string,
  content2: PropTypes.string,
}

export default ReadyToWriteBookComp
