import React from 'react';
import { logout } from '../firebase';

const Logout = () => {
  const handleLogout = async () => {
    try {
      await logout();
      // Redirect to login page or do something else on logout
      //setUserid(None);
      sessionStorage.removeItem('userid'); // Add userid to sessionStorage
      sessionStorage.removeItem('token'); 
      window.location.href = '/';
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <button onClick={handleLogout}>Logout</button>
  );
};

export default Logout;
